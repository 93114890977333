import { constants } from "@/configs";
import useListEventsQuery from "@/queries/useListEventsQuery";
import { ReloadOutlined } from "@ant-design/icons";
import { useIntersectionObserver, useWindowSize } from "@uidotdev/usehooks";
import { Button, Card, DatePicker, Empty, Select, Space, Switch, Table } from "antd";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { JsonView, allExpanded, defaultStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import { orderBy } from "lodash";

type EventsTableProps = {
  eventTypes: string[];
  showCard?: boolean;
};

const { RangePicker } = DatePicker;

const EventTypeLookup: any = {
  user_registered: "User Registered",
  identity_event: "Identity Event",
  company_added: "Company Added",
  company_settings_updated: "Company Settings Updated",
  company_updated: "Company Updated",
  not_billable_updated: "Not Billable Updated",
  water_right_added: "Water Right Added",
  water_right_updated: "Water Right Updated",
  well_added: "Well Added",
  well_updated: "Well Updated",
  token_assigned: "Token Assigned",
  token_replaced: "Token Replaced",
  user_invoice_updated: "User Invoice Updated",
  user_annual_subscription: "User Annual Subscription",
  clear_billable_items: "Clear Billable Items",
  user_invoice_created: "User Invoice Created",
  user_invoice_sent: "User Invoice Sent",
  user_invoice_paid: "User Invoice Paid",
  well_reading_added: "Well Reading Added",
  well_reading_updated: "Well Reading Updated",
  well_note_added: "Well Note Added",
  field_added: "Field Added",
  field_updated: "Field Updated",
  grouping_added: "Grouping Added",
  grouping_updated: "Grouping Updated",
  stock_added: "Stock Added",
  stock_updated: "Stock Updated",
  occupancy_added: "Head count Added",
  occupancy_updated: "Head count Updated",
  restriction_added: "Restriction Added",
  restriction_updated: "Restriction Updated",
  water_right_note_added: "Water Right Note Added",
  adjustment_transfer_added: "Adjustment Transfer Added",
  adjustment_transfer_updated: "Adjustment Transfer Updated",
  user_pending_billable_item_added: "Pending Billable Item Added",
  user_billing_note_added: "User Billing Note Added",
  user_billing_note_updated: "User Billing Note Updated",
  user_billing_note_deleted: "User Billing Note Deleted",
  user_updated: "User Updated",
  user_disabled: "User Disabled",
  user_enabled: "User Enabled",
  user_email_manually_confirmed: "User Email Manually Confirmed",
};

const RenderTable = (props: { isLoading: boolean; eventColumns: any[]; eventList: any[]; scroll: any }) => {
  return (
    <div>
      <Table
        virtual
        className="customSelectScrollBar"
        rowKey={(row: any) => row.eventId}
        loading={props.isLoading}
        columns={props.eventColumns}
        dataSource={props.eventList ?? []}
        size="small"
        pagination={false}
        scroll={props.scroll}
        style={{
          height: 400,
        }}
        expandable={{
          columnWidth: 55,
          expandedRowRender: (record: any) => (
            <Card title="Data">
              <JsonView data={record?.data} shouldExpandNode={allExpanded} style={defaultStyles} />
            </Card>
          ),
        }}
        locale={{
          emptyText: <Empty description="No Events" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
        }}
      />
    </div>
  );
};

const RenderHeader = (props: { dates: any; setDates: any; eventTypes: string[]; setFilterEventTypes: any; isLoading: boolean; handleRefresh: any; showEventId: boolean; setShowEventId: any }) => (
  <Space direction="horizontal">
    <Space>
      <RangePicker style={{ fontWeight: "normal" }} value={props.dates?.value} allowClear={false} onChange={(dates, dateStrings) => props.setDates({ value: dates, dateStrings })} />
      <Select style={{ width: 250, fontWeight: "normal" }} onChange={props.setFilterEventTypes} mode="multiple" placeholder="Filter event types" maxTagCount={1} allowClear>
        {orderBy(Object.keys(EventTypeLookup).filter((key) => (props.eventTypes.length === 0 ? key : props.eventTypes.includes(key)))).map((key) => (
          <Select.Option key={key} value={key}>
            {EventTypeLookup[key]}
          </Select.Option>
        ))}
      </Select>
      <Button disabled={props.isLoading} icon={<ReloadOutlined />} onClick={props.handleRefresh}>
        Refresh
      </Button>
      <Switch checked={props.showEventId} onChange={() => props.setShowEventId(!props.showEventId)} checkedChildren={"Event Id Shown"} unCheckedChildren={"Event Id Hidden"} />
    </Space>
  </Space>
);

const EventsTable: FC<EventsTableProps> = ({ eventTypes, showCard }) => {
  const [eventsDownRef, eventsDownEntry] = useIntersectionObserver();

  const [dates, setDates] = useState<any>({
    value: [dayjs(dayjs().subtract(1, "days").format("YYYY-MM-DD"), "YYYY-MM-DD"), dayjs(dayjs().format("YYYY-MM-DD"), "YYYY-MM-DD")],
    dateStrings: [dayjs().subtract(1, "days").format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
  });

  const [eventColumns, setEventColumns] = useState<any[]>([]);
  const [filterEventTypes, setFilterEventTypes] = useState<string[]>([]);

  const { eventList, isLoading, isFetchingNextPage, invalidateEvents, hasNextPage, fetchNextPage, totalItemCount } = useListEventsQuery({
    startDate: dates?.value?.[0],
    endDate: dates?.value?.[1],
    eventTypes: filterEventTypes.length === 0 ? (eventTypes.length === 0 ? filterEventTypes : eventTypes) : filterEventTypes,
  });

  const [showEventId, setShowEventId] = useState<boolean>(false);

  useEffect(() => {
    calculateEventColumns();
  }, [showEventId, eventList]);

  useEffect(() => {
    if (eventsDownEntry?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line
  }, [eventsDownEntry?.time, eventsDownEntry?.isIntersecting, hasNextPage]);

  const handleRefresh = () => {
    invalidateEvents();
  };

  const generateClientName = (clientId: any) => {
    switch (clientId) {
      case "adminwebportal":
        return "Web Portal";
      case "com.vandwater.swm":
        return "Mobile";
      case "com.vandwater.swm.development":
        return "Mobile";
      default:
        return clientId;
    }
  };

  const generateDescription = (record: any) => {
    let description = "-";

    switch (record.type) {
      case "user_registered":
        description = `[${record.data?.firstName + " " + record.data?.lastName}] registered as a user with username [${record.data?.userName}]`;
        break;
      case "identity_event":
        description = `${record.data?.eventData?.name} - ${generateClientName(record.data?.eventData?.clientId)} - ${record.data?.eventData?.username} ${record.data?.eventData?.message ? " - " + record.data?.eventData?.message : ""}`;
        break;
      case "company_added":
        description = `[${record.data?.firstName + " " + record.data?.lastName}] added a company [${record.data?.name}]`;
        break;
      case "company_updated":
        description = `[${record.data?.displayName}] updated company [${record.data?.name}]`;
        break;
      case "company_settings_updated":
        description = `[${record.data?.displayName}] updated company [${record.data?.companyName}] settings`;
        break;
      case "not_billable_updated":
        description = `[${record.data?.displayName}] set ${record.data?.entityType} [${record.data?.entityName}] as ${record.data?.billable ? "billable" : "NOT billable"}${record.data?.entityType === "Company" || record.data?.entityType === "User" ? "" : ` for company [${record.data?.companyName}]`}`;
        break;
      case "well_added":
        description = `[${record.data?.displayName}] added a well [${record.data?.name}]`;
        break;
      case "well_updated":
        description = `[${record.data?.displayName}] updated well [${record.data?.name}]`;
        break;
      case "token_assigned":
        description = `[${record.data?.displayName}] assigned a token to well [${record.data?.wellName}]`;
        break;
      case "token_replaced":
        description = `[${record.data?.displayName}] replaced token for well [${record.data?.wellName}]`;
        break;
      case "water_right_added":
        description = `[${record.data?.displayName}] added a water right [${record.data?.fileNumber} (${record.data?.cin ?? "-"}/${record.data?.pdiv ?? "-"})]`;
        break;
      case "water_right_updated":
        description = `[${record.data?.displayName}] updated water right [${record.data?.fileNumber} (${record.data?.cin ?? "-"}/${record.data?.pdiv ?? "-"})]`;
        break;
      case "user_invoice_updated":
        description = `[${record.data?.displayName}] updated [${record?.data?.username}] user invoice [${record.data?.financeDocument?.number}]`;
        break;
      case "user_annual_subscription":
        description = `[${record.data?.displayName}] generated [${record.data?.username}] annual user subsription for year [${record.data?.year}]`;
        break;
      case "clear_billable_items":
        description = `[${record.data?.displayName}] cleared billable items for [${record.data?.username}]`;
        break;
      case "user_invoice_created":
        description = `[${record.data?.displayName}] created user invoice for [${record.data?.billableUser?.user?.firstName} ${record.data?.billableUser?.user?.lastName}]`;
        break;
      case "user_invoice_sent":
        description = `[${record.data?.displayName}] sent user invoice to [${record.data?.username}]`;
        break;
      case "user_invoice_paid":
        description = `[${record.data?.displayName}] marked [${record.data?.userId}] user invoice as paid`;
        break;
      case "well_reading_added":
        description = `[${record.data?.displayName}] added a reading for well [${record.data?.wellName}]`;
        break;
      case "well_reading_updated":
        description = `[${record.data?.displayName}] updated a reading for well [${record.data?.wellName}]`;
        break;
      case "well_note_added":
        description = `[${record.data?.createdByUserName}] added a note for well [${record.data?.wellName}]`;
        break;
      case "field_added":
        description = `[${record.data?.displayName}] added a field [${record.data?.name}]`;
        break;
      case "field_updated":
        description = `[${record.data?.displayName}] updated a field [${record.data?.name}]`;
        break;
      case "grouping_added":
        description = `[${record.data?.displayName}] added a water right group [${record.data?.name}]`;
        break;
      case "grouping_updated":
        description = `[${record.data?.displayName}] updated a water right group [${record.data?.name}]`;
        break;
      case "stock_added":
        description = `[${record.data?.displayName}] added a stock [${record.data?.name}]`;
        break;
      case "stock_updated":
        description = `[${record.data?.displayName}] updated a stock [${record.data?.name}]`;
        break;
      case "occupancy_added":
        description = `[${record.data?.displayName}] added head counts for stock [${record.data?.stockName}]`;
        break;
      case "occupancy_updated":
        description = `[${record.data?.displayName}] updated head counts for stock [${record.data?.stockName}]`;
        break;
      case "restriction_added":
        description = `[${record.data?.displayName}] added a restriction [${record.data?.name}]`;
        break;
      case "restriction_updated":
        description = `[${record.data?.displayName}] updated a restriction [${record.data?.name}]`;
        break;
      case "water_right_note_added":
        description = `[${record.data?.displayName}] added a note for water right [${record.data?.fileNumber}]`;
        break;
      case "adjustment_transfer_added":
        description = `[${record.data?.displayName}] added an adjustment / transfer to water right [${record.data?.waterRightFileNumber}]`;
        break;
      case "adjustment_transfer_updated":
        description = `[${record.data?.displayName}] updated an adjustment / transfer to water right [${record.data?.waterRightFileNumber}]`;
        break;
      case "user_pending_billable_item_added":
        description = `[${record.data?.displayName}] added a pending billable item for [${record.data?.username}]`;
        break;
      case "user_billing_note_added":
        description = `[${record.data?.displayName}] added a billing note for user [${record.data?.username}]`;
        break;
      case "user_billing_note_updated":
        description = `[${record.data?.displayName}] updated a billing note for user [${record.data?.username}]`;
        break;
      case "user_billing_note_deleted":
        description = `[${record.data?.displayName}] deleted a billing note for user [${record.data?.username}]`;
        break;
      case "user_updated":
        description = `[${record.data?.displayName}] updated user details`;
        break;
      case "user_disabled":
        description = `[${record.data?.displayName}] disabled user [${record.data?.userName}]`;
        break;
      case "user_enabled":
        description = `[${record.data?.displayName}] enabled user [${record.data?.userName}]`;
        break;
      case "user_email_manually_confirmed":
        description = `[${record.data?.displayName}] manually confirmed the email for user [${record.data?.userName}]`;
        break;
    }

    return description;
  };

  const calculateEventColumns = () => {
    let tempColumns: any = [
      {
        title: "Timestamp",
        key: "timestamp",
        dataIndex: "timestamp",
        width: 180,
        render: (val: any, record: any, index: any) => (
          <>
            {dayjs(val).format(constants.dateTimeFormatWithSeconds)}
            {index === eventList?.length - 1 ? <span ref={eventsDownRef} /> : null}
          </>
        ),
      },
      {
        title: "Sequence",
        key: "sequence",
        dataIndex: "sequence",
        width: 100,
      },
      {
        title: "Type",
        key: "type",
        dataIndex: "type",
        width: 200,
        render: (val: any) => EventTypeLookup[val] ?? val,
      },
      {
        title: "Description",
        key: "description",
        dataIndex: "data",
        render: (val: any, record: any) => generateDescription(record),
      },
      {
        title: "Version",
        key: "version",
        dataIndex: "version",
        align: "center",
        width: 150,
      },
    ];

    if (showEventId) {
      tempColumns.push({
        title: "Event Id",
        key: "eventId",
        dataIndex: "eventId",
        width: 320,
      });
    }

    setEventColumns(tempColumns);
  };

  const windowSize = useWindowSize();

  return showCard ? (
    <Card
      title={
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <span>Events</span>
          <RenderHeader
            dates={dates}
            setDates={setDates}
            eventTypes={eventTypes}
            setFilterEventTypes={setFilterEventTypes}
            isLoading={isLoading}
            handleRefresh={handleRefresh}
            showEventId={showEventId}
            setShowEventId={setShowEventId}
          />
          <span>Total: {totalItemCount ?? "-"}</span>
        </div>
      }
      styles={{
        body: {
          height: "calc(100vh - 265px)",
          padding: 0,
        },
      }}
    >
      <RenderTable isLoading={isLoading || isFetchingNextPage} eventColumns={eventColumns} eventList={eventList} scroll={{ y: windowSize?.height ? windowSize.height - 345 : 600 }} />
    </Card>
  ) : (
    // <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-start", gap: 12 }}>
    <div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingBottom: 10 }}>
        <RenderHeader
          dates={dates}
          setDates={setDates}
          eventTypes={eventTypes}
          setFilterEventTypes={setFilterEventTypes}
          isLoading={isLoading}
          handleRefresh={handleRefresh}
          showEventId={showEventId}
          setShowEventId={setShowEventId}
        />
        <span style={{ fontSize: "16px", paddingRight: 5, fontWeight: 600 }}>Total: {totalItemCount ?? "-"}</span>
      </div>
      <RenderTable isLoading={isLoading || isFetchingNextPage} eventColumns={eventColumns} eventList={eventList} scroll={{ y: windowSize?.height ? windowSize.height - 400 : 600 }} />
    </div>
  );
};

export default EventsTable;
