import { deleteCompanyInvitedUser, resendUserInvite } from "@/apis/company.api";
import { AuditLogTable, StatusTag } from "@/components";
import { AuditLogType } from "@/components/AuditLogTable/AuditLogTable";
import { routes } from "@/configs";
import { UserRole } from "@/dtos/user.dto";
import useCompanyUsersQuery from "@/queries/useCompanyUsersQuery";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb } from "@/stores/breadcrumbs.store";
import { clearUserStateExcludingProfile } from "@/stores/user.store";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { useIntersectionObserver, useWindowSize } from "@uidotdev/usehooks";
import { Button, Card, Empty, Input, message, Popconfirm, Space, Switch, Table } from "antd";
import { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AddSelectCompanyModal, { AddSelectCompanyModalRef, EnitityName } from "../AddSelectCompanyModal/AddSelectCompanyModal";
import CompanyLabel from "../Lookups/CompanyLabel";
import PrintPage from "../PrintPage/PrintPage";

import "./UsersTable.scss";
import { saveSelectedCompanyId } from "@/stores/company.store";

export interface UsersTableProps {
  companyId?: string;
  applyPaddingOnSearch?: boolean;
}

const UsersTable: FC<UsersTableProps> = (props: UsersTableProps) => {
  const dispatch = useAppDispatch();
  const { navigate, updateQueryParams, queryParams } = useCustomNavigate();

  const { companyId, applyPaddingOnSearch = false } = props;

  const [usersDownRef, usersDownEntry] = useIntersectionObserver();

  const windowSize = useWindowSize();

  const { selectedCompany, selectedCompanyId } = useSelector((state: any) => state.company);

  const [userColumns, setUserColumns] = useState<any[]>([]);

  const [searchStringValue, setSearchStringValue] = useState<string | undefined>("");
  const [searchString, setSearchString] = useState<string | undefined>("");
  const [isActive, setIsActive] = useState<boolean | undefined>(true);

  const {
    companyUsers: users,
    isLoading,
    invalidateUsers,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    totalItemCount,
  } = useCompanyUsersQuery({
    isActive,
    searchString,
    companyId: companyId,
  });

  useEffect(() => {
    if (usersDownEntry?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line
  }, [usersDownEntry?.time, usersDownEntry?.isIntersecting, hasNextPage]);

  useEffect(() => {
    return () => {
      dispatch(clearUserStateExcludingProfile());
    };
  }, []);

  useEffect(() => {
    if (users) calculateUsersColumns();
  }, [users]);

  const handleDeleteInvitedUser = async (id: any) => {
    const response = await deleteCompanyInvitedUser(id);

    if (response.ok) {
      message.success("Deleted invited user successfully.");
      invalidateUsers();
    } else {
      message.error("Failed to delete the invited user.");
    }
  };

  const calculateUsersColumns = () => {
    let tempColumns: any[] = [
      {
        title: "Email",
        key: "email",
        dataIndex: "email",
        fixed: "left",
        render: (val: any, record: any, index: any) => (
          <>
            {selectedCompany?.userRole === UserRole.admin.label ? (
              <Button style={{ paddingLeft: 0, whiteSpace: "normal", wordBreak: "break-word", textAlign: "left" }} type="link" onClick={() => handleEdit(record)}>
                {val}
              </Button>
            ) : (
              val
            )}
            {index === users?.length - 1 ? <span ref={usersDownRef} /> : null}
          </>
        ),
      },
      {
        title: "Name",
        key: "name",
        dataIndex: "name",
        render: (val: any, record: any) => (record.type === "user" ? record.name : "N/A"),
      },
      {
        title: "Role",
        key: "role",
        dataIndex: "role",
        render: (val: any) => UserRole[val]?.label,
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (val: any, record: any) => <StatusTag status={val} date={record.date} />,
      },
    ];

    if (!selectedCompanyId) {
      tempColumns.push({
        title: "Company",
        key: "companyId",
        dataIndex: "companyId",
        render: (val: any) => <CompanyLabel companyId={val} />,
      });
    }

    if (selectedCompany?.userRole === UserRole.admin.label || !selectedCompanyId)
      tempColumns.push({
        title: "Actions",
        key: "action",
        width: 300,
        render: (value: any, record: any) => (
          <>
            {(selectedCompany?.userRole === UserRole.admin.label || !selectedCompanyId) && (
              <Button type="link" onClick={() => handleEdit(record)} style={{ paddingLeft: 0 }}>
                Edit
              </Button>
            )}

            {record.type === "invite" && (
              <>
                {" | "}
                {(selectedCompany?.userRole === UserRole.admin.label || !selectedCompanyId) && (
                  <Popconfirm
                    title={`Delete invited user`}
                    description={`Are you sure you want to permanently delete the invited user? This cannot be reversed.`}
                    // description="Are you sure you want to validate this well reading?"
                    onConfirm={() => handleDeleteInvitedUser(record.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button style={{ paddingRight: 10 }} type="link">
                      Delete
                    </Button>
                  </Popconfirm>
                )}
                {(selectedCompany?.userRole === UserRole.admin.label || !selectedCompanyId) && ` | `}
                {/* {selectedCompany?.userRole === UserRole.admin.label && <Button type='link' onClick={() => handleResendInvite(record)}>
                        Delete
                    </Button>}
                    {selectedCompany?.userRole === UserRole.admin.label && ` | `} */}
                <Button type="link" onClick={() => handleResendInvite(record)}>
                  Resend Invite
                </Button>
              </>
            )}
          </>
        ),
      });

    setUserColumns(tempColumns);
  };

  const handleEdit = (record: any) => {
    if (!selectedCompanyId) {
      dispatch(saveSelectedCompanyId(record?.companyId));
      dispatch(
        addBreadcrumb({
          type: `Edit`,
          url: `user/${record?.id}/edit?type=${record?.type}`,
        })
      );

      navigate(routes.userEdit, { id: record.id }, { type: record.type });
    } else {
      dispatch(
        addBreadcrumb({
          type: `Edit`,
          url: `user/${record?.id}/edit?type=${record?.type}`,
        })
      );

      navigate(routes.userEdit, { id: record.id }, { type: record.type });
    }
  };

  const handleResendInvite = async (record: any) => {
    const response = await resendUserInvite(record.id);
    if (response.ok) {
      message.success("Resent user invite successfully");
      navigate(routes.userList);
    } else {
      message.error("Failed to resent the user invite");
    }
  };

  const handleRefresh = () => {
    setSearchString(undefined);
    invalidateUsers();
  };

  const renderExpandData = (record: any) => {
    // TODO: Fix audit log for users
    return <AuditLogTable entityId={record.id} entityType={AuditLogType.User} />;
  };

  const handleOnChange = (value: boolean) => {
    if (!value) setIsActive(undefined);
    else setIsActive(true);
  };

  const addModalRef = useRef<AddSelectCompanyModalRef>(null);

  const renderContent = () => {
    return (
      <div>
        <Table
          className="customScrollBarStyle"
          pagination={false}
          rowKey={(row: any) => row.id}
          expandable={
            selectedCompany?.userRole === UserRole.admin.label || !selectedCompanyId
              ? {
                  expandedRowRender: (record: any) => renderExpandData(record),
                }
              : undefined
          }
          loading={isLoading || isFetchingNextPage}
          scroll={{ y: windowSize?.height ? windowSize.height - 360 : 600 }}
          columns={userColumns}
          dataSource={users ?? []}
          size="small"
          locale={{
            emptyText: (
              <Empty description={selectedCompanyId && selectedCompany?.userRole !== UserRole.admin.label ? "No permission to view users" : "No Users"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ),
          }}
        />
      </div>
    );
  };

  const handleAddUser = () => {
    if (selectedCompany?._companyId === "all") {
      addModalRef.current?.open();
      return;
    } else {
      handleNavigateToAdd();
    }
  };

  const handleNavigateToAdd = () => {
    dispatch(
      addBreadcrumb({
        type: `User / Add`,
        url: `user/add`,
      })
    );
    navigate(routes.userAdd);
  };

  // return (
  //   <Space direction="vertical" style={{ width: "100%" }}>
  //     <Card
  //       className="userList"
  //       id="userList"
  //       title="Users"
  //       extra={
  //         <>
  //           <Button onClick={handleAddUser} type="primary" icon={<PlusOutlined />}>
  //             Add User
  //           </Button>
  //         </>
  //       }
  //     >
  //       <PrintPage
  //         content={
  //           <div>
  //             <UsersTable />
  //           </div>
  //         }
  //       />
  //     </Card>
  //   </Space>
  // );

  return (
    <>
      <Card
        styles={{
          body: {
            height: companyId ? "calc(100vh - 620px)" : "calc(100vh - 320px)",
            padding: 0,
          },
        }}
        className="usersList"
        id="usersList"
        title={
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <span>Users</span>
            <Space direction="horizontal">
              <Space>
                <Input.Search
                  disabled={isLoading}
                  placeholder="Search users"
                  onSearch={(val) => {
                    setSearchString(val);
                  }}
                  onChange={(val) => setSearchStringValue(val.currentTarget.value)}
                  value={searchStringValue}
                  style={{ width: 400 }}
                  allowClear
                />
                <Button icon={<ReloadOutlined />} disabled={isLoading} onClick={handleRefresh}>
                  Refresh
                </Button>
                <Switch defaultChecked onChange={handleOnChange} checkedChildren="Active Only" unCheckedChildren="All" />
              </Space>
            </Space>

            <span style={{ display: "flex", alignItems: "center" }}>
              <span style={{ paddingRight: 16 }}>Total: {totalItemCount ?? "-"}</span>
              {!companyId && (selectedCompany?.userRole === UserRole.admin.label || selectedCompany?._companyId === "all") ? (
                <>
                  <Button onClick={handleAddUser} type="primary" icon={<PlusOutlined />}>
                    Add User
                  </Button>
                </>
              ) : (
                <></>
              )}
            </span>
          </div>
        }
      >
        <PrintPage content={renderContent()} />
      </Card>
      <AddSelectCompanyModal entityName={EnitityName.User} ref={addModalRef} onSubmit={handleNavigateToAdd} />
    </>
  );
};

export default UsersTable;
