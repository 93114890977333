import { FC } from "react";

import "./IntegrationList.scss";
import { IntegrationsTable } from "@/components";

const IntegrationList: FC = () => {
  return <IntegrationsTable />;
};

export default IntegrationList;
