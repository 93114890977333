import { generateConfigEncryptionKey } from "@/apis/integration.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

type useGenerateIntegrationConfigEncryptionKeyProps = {
  id?: string;
};

const useGenerateIntegrationConfigEncryptionKey = ({ id }: useGenerateIntegrationConfigEncryptionKeyProps) => {
  const queryClient = useQueryClient();

  const {
    data: encryptionKey,
    isFetching,
    isFetched,
    isLoading,
  } = useQuery({
    queryKey: [CacheKeys.integrations, CacheKeys.config, CacheKeys.encryption, { id }],
    queryFn: async () => {
      const response = await generateConfigEncryptionKey();

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch integration config encryption key");
    },
    ...defaultQueryConfig,
    enabled: !!!id,
    gcTime: Infinity,
    staleTime: Infinity,
  });

  const invalidateIntegrationConfigEncryptionKey = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.integrations, CacheKeys.config, CacheKeys.encryption],
    });
  }, [queryClient]);

  return {
    encryptionKey,
    isLoading,
    invalidateIntegrationConfigEncryptionKey,
    isFetching,
    isFetched,
  };
};

export default useGenerateIntegrationConfigEncryptionKey;
