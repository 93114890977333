import { deleteBillingUserNote, updateFinanceDocument } from "@/apis/aggregator.api";
import { constants } from "@/configs";
import DeleteBillingUserNoteButton from "@/pages/Admin/AdminBilling/components/DeleteBillingUserNoteButton";
import EditBillingUserNoteButton from "@/pages/Admin/AdminBilling/components/EditBillingUserNoteButton";
import FinanceDocumentStatusComponent from "@/pages/Admin/AdminBilling/components/FinanceDocumentStatusComponent";
import FinanceDocumentTypeComponent from "@/pages/Admin/AdminBilling/components/FinanceDocumentTypeComponent";
import useFinanceDocument from "@/queries/useFinanceDocument";
import useGetBillableUser from "@/queries/useGetBillableUser";
import useListBillingUsersInfiniteQuery from "@/queries/useListBillingUsersInfiniteQuery";
import { emailValidator } from "@/utils/isValidEmail";
import { CloseOutlined, PercentageOutlined, PlusOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Divider, Empty, Form, Input, InputNumber, message, Modal, Popconfirm, Row, Select, Space, Table, TableColumnsType, TableProps } from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import { add, isEqual } from "lodash";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import "./InvoicesModal.scss";
import BillingUserNoteAddEditModal from "@/pages/Admin/AdminBilling/components/BillingUserNoteAddEditModal";
import _ from "lodash";
import LateFeeModal from "./components/LateFeeModal";

const { TextArea } = Input;

interface InvoicesModalProps {
  id: string;
  userId: string;
  onClose: (reload?: boolean) => void;
}

interface LineItem {
  id: string;
  description: string;
  quantity: number;
  rate: number;
  amount: number;
  additionalDescription?: string;
  children?: LineItem[];
  parentId?: string;
}

type Payment = {
  id: string;
  date?: Date;
  reference: string;
  description: string;
  source: string;
  amount: number;
};

type BillingNote = {
  id: string;
  displayName: string;
  date: string;
  note: string;
  userId: string;
};

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  editable: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text" | "textArea" | "date";
  record: LineItem;
  index: number;
  required: boolean;
  requiredText?: string;
  prefix?: string;
  disabledOnChildEdit?: boolean;
}

const EditableCell: FC<PropsWithChildren<EditableCellProps>> = ({
  disabledOnChildEdit,
  editing,
  editable,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  required,
  requiredText,
  prefix,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editable && editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: required,
              message: requiredText,
            },
          ]}
        >
          {inputType === "date" && <DatePicker style={{ width: "100%" }} format={constants.dateFormat} disabled={disabledOnChildEdit} />}
          {inputType === "number" && <InputNumber style={{ width: "100%" }} decimalSeparator="." prefix={prefix} step={1.0} disabled={disabledOnChildEdit} />}
          {inputType === "text" && <Input disabled={disabledOnChildEdit} />}
          {inputType === "textArea" && (
            <TextArea
              autoSize
              styles={{
                textarea: {
                  maxHeight: "6em",
                },
              }}
              disabled={disabledOnChildEdit}
            />
          )}
        </Form.Item>
      ) : (
        <>
          {prefix ? prefix + " " : ""}
          {children}
        </>
      )}
    </td>
  );
};

const InvoicesModal: FC<InvoicesModalProps> = ({ id, onClose, userId }) => {
  const { financeDocument, isFetched, isLoading } = useFinanceDocument({ id });

  const { billingUser, isLoading: isBillingUserLoading, invalidateUser, isFetched: isBillingUserFetched } = useGetBillableUser({ userId: userId });

  const { invalidateBillingUsers } = useListBillingUsersInfiniteQuery({
    disabled: true,
  });

  const [form] = Form.useForm();
  const values = useWatch((x) => x, form);

  const [lineItemForm] = Form.useForm<LineItem>();
  const [parentId, setParentId] = useState<string | undefined>(undefined);

  const rate = useWatch("rate", lineItemForm);
  const quantity = useWatch("quantity", lineItemForm);
  const amount = (rate ?? 0) * (quantity ?? 0);

  const [paymentsForm] = Form.useForm();
  const paymentAmount = useWatch("amount", paymentsForm);

  const [billingUserNotesForm] = Form.useForm();

  const [lineItems, setLineItems] = useState<LineItem[]>([]);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [billingNotes, setBillingNotes] = useState<BillingNote[]>([]);

  const [editingKey, setEditingKey] = useState<string>();
  const [adding, setAdding] = useState<boolean>(false);

  const [editingPaymentKey, setEditingPaymentKey] = useState<string>();
  const [addingPayment, setAddingPayment] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);

  const [billingNoteColumns, setBillingNoteColumns] = useState<any[]>([]);
  const [editingNoteId, setEditingNoteId] = useState<string | undefined>(undefined);

  const [billingModalState, setBillingModalState] = useState<any>({ open: false });

  // useEffect(() => {
  //   console.log("billable user => ", billingUser);
  // }, [billingUser])

  // useEffect(() => {
  //   console.log("user id => ", userId);
  // }, [userId])

  useEffect(() => {
    if (billingUser) {
      setBillingNotes(billingUser?.billingNotes);
    }
  }, [billingUser]);

  useEffect(() => {
    calculateBillingNoteColumns();
  }, [billingNotes]);

  const isEditing = (record: LineItem) => record.id === editingKey;
  const isEditingPayment = (record: Payment) => record.id === editingPaymentKey;

  const edit = (record: LineItem) => {
    setEditingKey(record.id);
    setParentId(record.parentId);
    lineItemForm.setFieldsValue({ ...record, additionalDescription: record.parentId ? "" : record.additionalDescription });
  };

  const editPayment = (record: Payment) => {
    setEditingPaymentKey(record.id);
    paymentsForm.setFieldsValue({ ...record });
  };

  const handleDelete = async (billingNote: any, onCompleted: () => void) => {
    const response = await deleteBillingUserNote(billingNote?.id, {
      userId: userId!,
      note: billingNote?.note,
    });

    if (response.ok) {
      message.success("User billing note deleted successfully");
      setTimeout(() => {
        invalidateBillingUsers();
        invalidateUser();
      }, 2000);
    } else {
      message.error("Failed to delete user billing note");
    }

    onCompleted();
  };

  const calculateBillingNoteColumns = () => {
    const tempColumns: any[] = [
      {
        title: "Date",
        key: "date",
        dataIndex: "date",
        width: 150,
        render: (val: any) => dayjs(val).format(constants.dateTimeFormat),
      },
      {
        title: "User",
        key: "displayName",
        dataIndex: "displayName",
        width: 150,
      },
      {
        title: "Note",
        key: "note",
        dataIndex: "note",
        render: (val: any) => (
          <div style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
            {val.split("\n").map((line: string, index: number) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </div>
        ),
      },
      {
        title: "Actions",
        width: 100,
        render: (val: any, record: any) => (
          <>
            <EditBillingUserNoteButton billingNote={record} onEdit={setEditingNoteId} />
            {" | "}
            <DeleteBillingUserNoteButton billingNote={record} onDelete={handleDelete} />
          </>
        ),
      },
    ];

    setBillingNoteColumns(tempColumns);
  };

  const cancel = () => {
    setEditingKey(undefined);
    setAdding(false);
    setParentId(undefined);
  };

  const cancelPayment = () => {
    setEditingPaymentKey(undefined);
    setAddingPayment(false);
  };

  const save = (id: string, parentId?: string) => {
    lineItemForm
      .validateFields()
      .then((values) => {
        if (parentId) {
          const newData = [...lineItems];
          const index = newData.findIndex((item) => parentId === item.id);
          if (index > -1) {
            const item = newData[index];
            const childIndex = item.children!.findIndex((child) => id === child.id);
            if (childIndex > -1) {
              const child = item.children![childIndex];
              item.children!.splice(childIndex, 1, { ...child, ...values, amount: values.rate * values.quantity });
            }

            item.quantity = item.children!.reduce((acc, child) => acc + child.quantity, 0);
            item.amount = item.children!.reduce((acc, child) => acc + child.amount, 0);
            newData.splice(index, 1, item);
            setLineItems(newData);
          }
        } else {
          setEditingKey(undefined);
          const newData = [...lineItems];
          const index = newData.findIndex((item) => id === item.id);
          if (index > -1) {
            const item = newData[index];
            item.children = item.children?.map((child) => {
              return { ...child, rate: values.rate, amount: values.rate * child.quantity };
            });
            newData.splice(index, 1, { ...item, ...values, amount: values.rate * values.quantity });
            setLineItems(newData);
          }
        }
        cancel();
      })
      .catch(() => message.error("Please fill out all required fields."));
  };

  const savePayment = (id: string) => {
    paymentsForm
      .validateFields()
      .then((values) => {
        setEditingPaymentKey(undefined);
        const newData = [...payments];
        const index = newData.findIndex((item) => id === item.id);
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, ...values });
          setPayments(newData);
        }
        cancelPayment();
      })
      .catch(() => message.error("Please fill out all required fields."));
  };

  const handleAdd = () => {
    setAdding(true);
    const newItem: LineItem = {
      id: uuidv4(),
      description: "",
      additionalDescription: "",
      quantity: 1,
      amount: 0,
      rate: 0,
    };
    setLineItems((existingItems) => [...existingItems, newItem]);
    edit(newItem);
  };

  const handleAddSummary = (record: LineItem) => {
    if (!record.parentId) {
      setAdding(true);
      const newData = [...lineItems];
      const index = newData.findIndex((item) => record.id === item.id);
      if (index > -1) {
        const item = newData[index];
        const newItem: LineItem = {
          id: uuidv4(),
          description: "",
          additionalDescription: "",
          quantity: 1,
          amount: 0,
          rate: item.rate,
          parentId: record.id,
        };
        item.children = item.children ? [...item.children, newItem] : [newItem];
        newData.splice(index, 1, item);
        setLineItems(newData);
        edit(newItem);
      }
    }
  };

  const [lateFeeModal, setLateFeeModal] = useState({ open: false });

  const handleLateFeeModalOpen = () => {
    setLateFeeModal({ open: true });
  };

  const handleLateFeeModalCancel = () => {
    setLateFeeModal({ open: false });
  };

  const handleLateFeeModalOk = (percentage: number) => {
    handleAddLateFee(total, percentage);
  };

  const handleAddLateFee = (total: number, percentage: number) => {
    setLateFeeModal({ open: false });
    if (total <= 0) return;

    const value = Math.round(((total * percentage) / 100) * 100) / 100;

    const newItem: LineItem = {
      id: uuidv4(),
      description: `Late Fee (${percentage.toFixed(1)}%)`,
      additionalDescription: "",
      quantity: 1,
      amount: value,
      rate: value,
    };
    setLineItems((existingItems) => [...existingItems, newItem]);
  };

  const handleRemove = (id: string, parentId?: string) => {
    if (parentId) {
      const newData = [...lineItems];
      const index = newData.findIndex((item) => parentId === item.id);
      if (index > -1) {
        const item = newData[index];
        const childIndex = item.children!.findIndex((child) => id === child.id);
        if (childIndex > -1) {
          item.children!.splice(childIndex, 1);
        }
        if (item.children!.length === 0) {
          item.children = undefined;
        } else {
          item.quantity = item.children!.reduce((acc, child) => acc + child.quantity, 0);
          item.amount = item.children!.reduce((acc, child) => acc + child.amount, 0);
        }
        newData.splice(index, 1, item);
        setLineItems(newData);
      }
    } else {
      setLineItems((existingItems) => existingItems.filter((x) => x.id !== id));
    }
  };

  const handleAddPayment = () => {
    setAddingPayment(true);
    const newItem: Payment = {
      id: uuidv4(),
      reference: "",
      description: "",
      source: "",
      amount: 0,
    };
    setPayments((existingItems) => [...existingItems, newItem]);
    editPayment(newItem);
  };

  const handleRemovePayment = (id: string) => {
    setPayments((existingItems) => existingItems.filter((x) => x.id !== id));
  };

  const handleSubmit = () => {
    if (!!editingKey) {
      message.error("Changes cannot be saved while editing or adding a line item");
      return;
    }

    if (!!editingPaymentKey) {
      message.error("Changes cannot be saved while editing or adding a received payment");
      return;
    }

    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);

        const mappedLineItems = lineItems.map((x) => {
          return {
            id: x.id,
            description: x.description,
            additionalDescription: x.additionalDescription,
            quantity: x.quantity,
            rate: x.rate,
            amount: x.amount,
            quantitySummaries: x.children?.map((child) => {
              return {
                description: child.description,
                quantity: child.quantity,
              };
            }),
          };
        });

        const submitData = {
          ...financeDocument,
          ...values,
          date: dayjs(values.date).format("MM/DD/YYYY"),
          lineItems: mappedLineItems,
          payments: payments,
          totalAmount: lineItems.reduce((acc, item) => acc + item.amount, 0),
        };

        var response = await updateFinanceDocument({ id: financeDocument.id, financeDocument: submitData });

        if (response.ok) {
          message.success("Invoice updated successfully");
          onClose(true);
        } else {
          message.error("Failed to update invoice");
        }

        setLoading(false);
      })
      .catch(() => message.error("Please fill out all required fields."));
  };

  const columns = [
    {
      title: "Description",
      dataIndex: "description",
      editable: true,
      inputType: "text",
      required: true,
      requiredText: "Please input a description!",
    },
    {
      title: "Additional Description",
      dataIndex: "additionalDescription",
      editable: true,
      inputType: "textArea",
      required: false,
      disabledOnChildEdit: true,
      render: (val: string) => (val && val.length > 50 ? val.substring(0, 50) + "..." : val),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      editable: true,
      inputType: "number",
      required: true,
      requiredText: "Please input a quantity!",
      width: 150,
    },
    {
      title: "Rate",
      dataIndex: "rate",
      editable: true,
      inputType: "number",
      required: true,
      requiredText: "Please input a rate!",
      width: 150,
      prefix: "$",
      disabledOnChildEdit: true,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      editable: false,
      width: 150,
      prefix: "$",
      render: (_: any, record: LineItem) => (isEditing(record) ? amount.toFixed(2) : (record.rate * record.quantity).toFixed(2)),
    },
    {
      title: "Actions",
      dataIndex: "operation",
      width: 250,
      render: (_: any, record: LineItem) => {
        const editable = isEditing(record);
        return editable ? (
          <>
            <Button type="link" style={{ padding: 0, paddingRight: 5 }} onClick={() => save(record.id, record.parentId)}>
              Done
            </Button>
            {" | "}
            {adding ? (
              <Popconfirm
                title={record.parentId ? "Are you sure you want to remove this summary line item?" : "Are you sure you want to remove this line item?"}
                onConfirm={() => {
                  cancel();
                  handleRemove(record.id, record.parentId);
                }}
                placement="topLeft"
              >
                <Button type="link" style={{ padding: 0, paddingLeft: 5 }}>
                  Cancel
                </Button>
              </Popconfirm>
            ) : (
              <Button type="link" style={{ padding: 0, paddingLeft: 5 }} onClick={() => cancel()}>
                Cancel
              </Button>
            )}
          </>
        ) : (
          <>
            <Button type="link" style={{ padding: 0, paddingRight: 5 }} disabled={!!editingKey} onClick={() => edit(record)}>
              Edit
            </Button>
            {" | "}
            <Popconfirm
              title={record.parentId ? "Are you sure you want to remove this summary line item?" : "Are you sure you want to remove this line item?"}
              onConfirm={() => handleRemove(record.id, record.parentId)}
              placement="topLeft"
            >
              <Button type="link" style={{ padding: 0, paddingLeft: 5, paddingRight: 5 }} disabled={!!editingKey}>
                Remove
              </Button>
            </Popconfirm>
            {!record.parentId && " | "}
            {!record.parentId && (
              <Button type="link" style={{ padding: 0, paddingLeft: 5 }} disabled={!!editingKey} onClick={() => handleAddSummary(record)}>
                Add Summary
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const paymentColumns = [
    {
      title: "Date",
      dataIndex: "date",
      editable: true,
      inputType: "date",
      required: true,
      requiredText: "Please select a date!",
      render: (val: Date) => (val ? dayjs(val).format(constants.dateFormat) : "-"),
    },
    {
      title: "Reference",
      dataIndex: "reference",
      editable: true,
      inputType: "text",
      required: true,
      requiredText: "Please input a reference!",
    },
    {
      title: "Description",
      dataIndex: "description",
      editable: true,
      inputType: "text",
    },
    {
      title: "Source",
      dataIndex: "source",
      editable: true,
      inputType: "text",
      required: true,
      requiredText: "Please input a source!",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      editable: true,
      inputType: "number",
      required: true,
      requiredText: "Please input an amount!",
      width: 150,
      prefix: "$",
      render: (val: any) => val.toFixed(2),
    },
    {
      title: "Actions",
      dataIndex: "operation",
      width: 130,
      render: (_: any, record: Payment) => {
        const editable = isEditingPayment(record);
        return editable ? (
          <>
            <Button type="link" style={{ padding: 0, paddingRight: 5 }} onClick={() => savePayment(record.id)}>
              Done
            </Button>
            {" | "}
            {addingPayment ? (
              <Popconfirm
                title="Are you sure you want to remove this line item?"
                onConfirm={() => {
                  cancelPayment();
                  handleRemovePayment(record.id);
                }}
                placement="topLeft"
              >
                <Button type="link" style={{ padding: 0, paddingLeft: 5 }}>
                  Cancel
                </Button>
              </Popconfirm>
            ) : (
              <Button type="link" style={{ padding: 0, paddingLeft: 5 }} onClick={() => cancelPayment()}>
                Cancel
              </Button>
            )}
          </>
        ) : (
          <>
            <Button type="link" style={{ padding: 0, paddingRight: 5 }} disabled={!!editingPaymentKey} onClick={() => editPayment(record)}>
              Edit
            </Button>
            {" | "}
            <Popconfirm title="Are you sure you want to remove this line item?" onConfirm={() => handleRemovePayment(record.id)} placement="topLeft">
              <Button type="link" style={{ padding: 0, paddingLeft: 5 }} disabled={!!editingPaymentKey}>
                Remove
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const mergedColumns: TableProps<LineItem>["columns"] = columns.map((col) => {
    return {
      ...col,
      onCell: (record: LineItem) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        required: col.required,
        requiredText: col.requiredText,
        prefix: col.prefix,
        editable: col.editable,
        disabledOnChildEdit: col.disabledOnChildEdit && record.parentId ? true : false,
      }),
    };
  });

  const mergedPaymentColumns: TableProps<Payment>["columns"] = paymentColumns.map((col) => {
    return {
      ...col,
      onCell: (record: Payment) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditingPayment(record),
        required: col.required,
        requiredText: col.requiredText,
        prefix: col.prefix,
        editable: col.editable,
      }),
    };
  });

  useEffect(() => {
    if (editingNoteId !== undefined) {
      setBillingModalState({ open: true });
    }
  }, [editingNoteId]);

  useEffect(() => {
    if (financeDocument) {
      form.setFieldsValue({
        number: financeDocument.number,
        numberPostScript: financeDocument.numberPostScript,
        date: dayjs(financeDocument.date, "MM-DD-YYYY"),
        billToEmail: financeDocument.billToEmail,
        billTo: financeDocument.billTo,
        terms: financeDocument.terms,
        annual: financeDocument.annual,
      });
      var mappedLineItems = financeDocument.lineItems.map((x: any) => ({
        ...x,
        children: x.quantitySummaries?.map((summary: any) => {
          return {
            ...summary,
            parentId: x.id,
            id: uuidv4(),
            description: summary.description,
            quantity: summary.quantity,
            rate: x.rate,
            amount: x.rate * summary.quantity,
          };
        }),
      }));

      setLineItems(mappedLineItems);
      setPayments(financeDocument.payments.map((x: any) => ({ ...x, date: typeof x.date === "string" ? dayjs(x.date) : x.date })));
    }
  }, [financeDocument, form]);

  useEffect(() => {
    if (financeDocument && values) {
      const original = {
        number: financeDocument.number,
        date: financeDocument.date,
        billToEmail: financeDocument.billToEmail,
        billTo: financeDocument.billTo,
        lineItems: financeDocument.lineItems,
        terms: financeDocument.terms,
        annual: financeDocument.annual,
      };
      const updated = {
        number: values.number,
        date: dayjs(values.date).format("MM-DD-YYYY"),
        billToEmail: values.billToEmail,
        billTo: values.billTo,
        lineItems: lineItems,
        terms: values.terms,
        annual: values.annual,
      };
      if (isEqual(original, updated)) setChanged(false);
      else setChanged(true);
    }
  }, [financeDocument, lineItems, values]);

  const total = lineItems.filter((x) => x.id !== editingKey && x.parentId === undefined).reduce((acc, item) => acc + item.amount, 0) + (parentId ? 0 : amount);
  const totalPayments = payments.filter((x) => x.id !== editingPaymentKey).reduce((acc, item) => acc + item.amount, 0) + (paymentAmount ?? 0);

  return (
    <>
      <Modal
        open
        width={"95%"}
        title={
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", fontSize: 20, fontWeight: "bold" }}>
            <div>
              <FinanceDocumentTypeComponent type={financeDocument?.type} />
            </div>
            <div>
              <FinanceDocumentStatusComponent status={financeDocument?.status} />
            </div>
          </div>
        }
        okText="Save Changes"
        cancelText="Cancel"
        onOk={handleSubmit}
        onCancel={() => {
          onClose(false);
        }}
        closable={false}
        maskClosable={false}
        cancelButtonProps={{
          icon: <CloseOutlined />,
          disabled: loading,
          loading: loading,
        }}
        okButtonProps={{
          icon: <ThunderboltOutlined />,
          disabled: !changed || loading || !!editingKey || !!editingPaymentKey,
          loading: loading,
        }}
        styles={{
          body: {
            padding: 0,
            paddingBottom: 20,
            maxHeight: "calc(100vh - 300px)",
            overflowY: "auto",
          },
        }}
      >
        {isFetched && (
          <Form form={form} labelWrap={true} layout="horizontal" name="invoiceForm">
            <Divider orientation="left">Invoice Details</Divider>
            <Card className="removeBoxShadow removeMargin">
              <Row>
                <Col span={12}>
                  <Form.Item label="Invoice Number" labelCol={{ span: 6 }} style={{ marginBottom: "5px" }} required>
                    <Space.Compact>
                      <Form.Item
                        name="number"
                        rules={[
                          {
                            required: true,
                            message: "Please input an invoice number",
                          },
                        ]}
                        noStyle
                      >
                        <InputNumber style={{ width: "100%" }} placeholder="Invoice number" min={0} formatter={(value) => `${value}`.replace(",", "").replace(".", "")} />
                      </Form.Item>
                      <Form.Item name="numberPostScript" noStyle>
                        <Input addonBefore={"-"} style={{ width: "100%" }} placeholder="Post script (Optional)" />
                      </Form.Item>
                    </Space.Compact>
                  </Form.Item>
                  <Form.Item
                    labelCol={{ span: 6 }}
                    label="Date"
                    name="date"
                    rules={[
                      {
                        required: true,
                        message: "Please input a date for the invoice",
                      },
                    ]}
                    style={{ marginBottom: "5px" }}
                  >
                    <DatePicker style={{ width: "100%" }} placeholder="Select the date of the adjustment" format={constants.dateFormat} allowClear />
                  </Form.Item>
                  <Form.Item
                    labelCol={{ span: 6 }}
                    label="Terms"
                    name="terms"
                    rules={[
                      {
                        required: true,
                        message: "Please input the invoice terms",
                      },
                    ]}
                    style={{ marginBottom: "5px" }}
                  >
                    <Input placeholder="Terms" />
                  </Form.Item>
                  <Form.Item
                    labelCol={{ span: 6 }}
                    label="Annual Terms"
                    name="annual"
                    rules={[
                      {
                        required: true,
                        message: "Please input the invoice annual terms",
                      },
                    ]}
                  >
                    <Input placeholder="Annual terms" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    labelCol={{ span: 6 }}
                    label="Bill To Email"
                    name="billToEmail"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please input atleast one email address.",
                      },
                      {
                        message: "An invalid email was entered.",
                        validator: emailValidator,
                      },
                    ]}
                    style={{ marginBottom: "5px" }}
                  >
                    <Select mode="tags" maxTagCount={5} style={{ width: "100%" }} placeholder="Billing Emails" />
                  </Form.Item>
                  <Form.Item labelCol={{ span: 6 }} label="Bill To" name="billTo" style={{ marginBottom: "5px" }}>
                    <TextArea placeholder="Bill to" autoSize autoFocus={false} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Form>
        )}
        <Form form={lineItemForm} labelWrap={true} layout="horizontal" name="lineItemForm">
          <Divider orientation="left">Line Items</Divider>
          <Card className="removeBoxShadow removeMargin">
            <Space style={{ width: "100%", justifyContent: "flex-end" }}>
              <Button type="default" icon={<PlusOutlined />} disabled={!!editingKey} onClick={handleAdd}>
                Add Line Item
              </Button>
              <Button type="default" icon={<PercentageOutlined />} disabled={!!editingKey} onClick={handleLateFeeModalOpen}>
                Add Late Fee
              </Button>
            </Space>

            <Table
              loading={isLoading}
              size="small"
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              dataSource={lineItems}
              columns={mergedColumns}
              rowClassName="editable-row"
              pagination={false}
              footer={() => (
                <Button type="dashed" icon={<PlusOutlined />} disabled={!!editingKey} onClick={handleAdd} style={{ padding: 0, width: "100%", borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
                  Add Line Item
                </Button>
              )}
              style={{ marginTop: 20 }}
              summary={() => (
                <>
                  <Table.Summary.Row style={{ fontWeight: "bold" }}>
                    <Table.Summary.Cell colSpan={6} index={0}>
                      {" "}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row style={{ fontWeight: "bold" }}>
                    <Table.Summary.Cell colSpan={4} index={0}>
                      Total
                    </Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={2} index={1}>
                      $ {(total > 0 ? total : 0).toFixed(2)}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              )}
            />
          </Card>
        </Form>

        <Form form={paymentsForm} labelWrap={true} layout="horizontal" name="lineItemForm">
          <Divider orientation="left">Payments Received</Divider>
          <Card className="removeBoxShadow removeMargin">
            <Table
              loading={isLoading}
              size="small"
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              dataSource={payments}
              columns={mergedPaymentColumns}
              rowClassName="editable-row"
              pagination={false}
              locale={{
                emptyText: <Empty description="No Payemnts Received" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
              }}
              footer={() => (
                <Button
                  type="dashed"
                  icon={<PlusOutlined />}
                  disabled={!!editingPaymentKey}
                  onClick={handleAddPayment}
                  style={{ padding: 0, width: "100%", borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
                >
                  Add Payment Received
                </Button>
              )}
              style={{ marginTop: 20 }}
              summary={() => (
                <>
                  <Table.Summary.Row style={{ fontWeight: "bold" }}>
                    <Table.Summary.Cell colSpan={6} index={0}>
                      {" "}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row style={{ fontWeight: "bold" }}>
                    <Table.Summary.Cell colSpan={4} index={0}>
                      Total
                    </Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={2} index={1}>
                      $ {(total > 0 ? total : 0).toFixed(2)}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row style={{ fontWeight: "bold" }}>
                    <Table.Summary.Cell colSpan={4} index={0}>
                      Total Payments Received
                    </Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={2} index={1}>
                      $ ({totalPayments.toFixed(2)})
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row style={{ fontWeight: "bold" }}>
                    <Table.Summary.Cell colSpan={4} index={0}>
                      Total Outstanding
                    </Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={2} index={1}>
                      $ {((total > 0 ? total : 0) - totalPayments).toFixed(2)}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              )}
            />
          </Card>
        </Form>

        <Form form={billingUserNotesForm} labelWrap={true} layout="horizontal" name="billingUserNotesForm">
          <Divider orientation="left">Billing User Notes</Divider>
          <Card className="removeBoxShadow removeMargin">
            <Table
              dataSource={billingNotes ? _.orderBy(billingNotes, ["date"], ["desc"]) : []}
              columns={billingNoteColumns}
              pagination={false}
              size="small"
              locale={{
                emptyText: <Empty description="No Billing Notes" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
              }}
              bordered
              footer={() => (
                <Button
                  type="dashed"
                  icon={<PlusOutlined />}
                  onClick={() => setBillingModalState({ open: true })}
                  style={{ padding: 0, width: "100%", borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
                >
                  Add Billing Note
                </Button>
              )}
            />
          </Card>
        </Form>
      </Modal>
      {billingModalState.open && (
        <BillingUserNoteAddEditModal
          userId={userId!}
          open={billingModalState.open}
          onClose={() => {
            if (editingNoteId) setEditingNoteId(undefined);
            setTimeout(() => {
              invalidateBillingUsers();
              invalidateUser();
            }, 2000);
            setBillingModalState({ open: false });
          }}
          billingNotes={billingNotes ?? []}
          billingNoteId={editingNoteId}
        />
      )}
      {lateFeeModal.open && <LateFeeModal onCancel={handleLateFeeModalCancel} onOk={handleLateFeeModalOk} />}
    </>
  );
};

export default InvoicesModal;
