import { EnvironmentFilled, FacebookOutlined, InstagramOutlined, LinkedinOutlined, MailFilled, PhoneFilled } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row, Space, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { addContactUs } from "@/apis/communication.api";
import { PageContent, PageHeader } from "@/components";
import PageFooter from "@/components/PageComponents/PageFooter/PageFooter";
import { FC, useState } from "react";
import useWindowSize from "@/services/useWindowSize";

const ContactUs: FC = () => {
  const [form] = useForm();
  const size = useWindowSize();

  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async (values: any) => {
    setLoading(true);

    const response = await addContactUs(values);
    if (response.ok) {
      message.success("Contact Us Message sent successfully.");
      form.resetFields();
    } else {
      message.error("Contact Us Message failed.");
    }
    setLoading(false);
  };

  const renderActionButtons = () => (
    <Button disabled={loading} loading={loading} type="primary" onClick={() => form.submit()}>
      Submit
    </Button>
  );

  return (
    <div id="contactUs">
      <PageHeader title="Contact VandWater" backgroundImageUri="images/VandWater-Banner.png" />
      <PageContent
        content={
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row gutter={[12, 12]} style={{ width: "100%", maxWidth: 760 }}>
              {[
                {
                  title: "Call VandWater, Inc.",
                  description: "785.527.9041",
                  icon: (
                    <PhoneFilled
                      style={{
                        color: "#00007f",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "30px",
                      }}
                    />
                  ),
                  onClick: () => {
                    const link = document.createElement("a");
                    link.href = "tel:7855279041";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  },
                },
                {
                  title: "Email VandWater, Inc.",
                  description: "info@vandwater.com",
                  icon: (
                    <MailFilled
                      style={{
                        color: "#00007f",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "30px",
                      }}
                    />
                  ),
                  onClick: () => {
                    const link = document.createElement("a");
                    link.href = "mailto:info@vandwater.com";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  },
                },
                {
                  title: "Corporate Office",
                  description: (
                    <>
                      1817 E Frontage Rd
                      <br />
                      Belleville KS 66935
                    </>
                  ),
                  icon: (
                    <EnvironmentFilled
                      style={{
                        color: "#00007f",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "30px",
                      }}
                    />
                  ),
                  onClick: () => {
                    const link = document.createElement("a");
                    link.href = "https://goo.gl/maps/9XvEEBckbsFXq3pL6";
                    link.target = "_blank";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  },
                },
              ].map((item) => (
                <Col xs={24} sm={12} md={8} lg={8} xl={8} style={{ width: "100%", padding: 10, cursor: "pointer" }}>
                  <Card style={{ width: "100%", height: "calc(100% - 10px)" }} bodyStyle={{ padding: 16 }} onClick={item.onClick}>
                    {item.icon}
                    <h1
                      style={{
                        wordWrap: "break-word",
                        lineHeight: 1.5,
                        boxSizing: "border-box",
                        margin: 0,
                        marginTop: 20,
                        border: 0,
                        outline: 0,
                        verticalAlign: "baseline",
                        background: "transparent",
                        fontWeight: "normal",
                        fontSize: "18px",
                        color: "#333",
                        textAlign: "center",
                      }}
                    >
                      {item.title}
                    </h1>
                    <p
                      style={{
                        lineHeight: "1.7em",
                        wordWrap: "break-word",
                        boxSizing: "border-box",
                        margin: 0,
                        border: 0,
                        outline: 0,
                        fontSize: "20px",
                        verticalAlign: "baseline",
                        background: "transparent",
                        fontWeight: "lighter",
                        color: "#666",
                        textAlign: "center",
                      }}
                    >
                      {item.description}
                    </p>
                  </Card>
                </Col>
              ))}
            </Row>
            <Row gutter={[12, 12]} style={{ width: "100%", maxWidth: 760 }}>
              {[
                {
                  title: "Facebook",
                  description: "",
                  icon: (
                    <FacebookOutlined
                      style={{
                        color: "#00007f",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "30px",
                      }}
                    />
                  ),
                  onClick: () => {
                    const link = document.createElement("a");
                    link.href = "https://www.facebook.com/profile.php?id=61556817042732";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  },
                },
                {
                  title: "Instagram",
                  description: "",
                  icon: (
                    <InstagramOutlined
                      style={{
                        color: "#00007f",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "30px",
                      }}
                    />
                  ),
                  onClick: () => {
                    const link = document.createElement("a");
                    link.href = "https://www.instagram.com/vandwater_inc";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  },
                },
                {
                  title: "LinkedIn",
                  description: "",
                  icon: (
                    <LinkedinOutlined
                      style={{
                        color: "#00007f",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "30px",
                      }}
                    />
                  ),
                  onClick: () => {
                    const link = document.createElement("a");
                    link.href = "https://www.linkedin.com/company/vandwater-inc/?viewAsMember=true";
                    link.target = "_blank";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  },
                },
              ].map((item) => (
                <Col xs={24} sm={12} md={8} lg={8} xl={8} style={{ width: "100%", padding: 10, cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Button style={{ textAlign: "center" }} type="link" icon={item.icon} onClick={item.onClick}></Button>
                </Col>
              ))}
            </Row>
            <Form
              form={form}
              labelWrap={true}
              labelCol={{ span: size.width < 700 ? 24 : 8 }}
              wrapperCol={{ span: size.width < 700 ? 24 : 16 }}
              onFinish={onFinish}
              autoComplete="off"
              style={{ maxWidth: 760, width: "100%" }}
              layout={size.width < 700 ? "vertical" : "horizontal"}
            >
              <Card bordered={false} className="removeBoxShadow removeMargin" id="contactUsForm" title={"Send us a message"} actions={[renderActionButtons()]}>
                <Form.Item label="Name" required>
                  <Space.Compact style={{ width: "100%" }}>
                    <Form.Item
                      name="firstName"
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: "Please enter your first name",
                        },
                      ]}
                    >
                      <Input placeholder="First Name" />
                    </Form.Item>
                    <Form.Item
                      name="lastName"
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: "Please enter your last name",
                        },
                      ]}
                    >
                      <Input placeholder="Last Name" />
                    </Form.Item>
                  </Space.Compact>
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not a valid e-mail",
                    },
                    {
                      required: true,
                      message: "Please input your e-mail",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  label="Phone Number"
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number",
                    },
                  ]}
                >
                  <Input placeholder="Phone Number" />
                </Form.Item>
                <Form.Item label="Promo Code" name="promoCode">
                  <Input placeholder="Promo Code" />
                </Form.Item>
                <Form.Item label="Comment or Message" name="message" rules={[{ required: true, message: "Please enter your message" }]}>
                  <Input.TextArea rows={4} placeholder="Message" />
                </Form.Item>
              </Card>
            </Form>
          </div>
        }
      />
      <PageFooter />
    </div>
  );
};

export default ContactUs;
