import CompanyLabel from "@/components/Lookups/CompanyLabel";
import PrintPage from "@/components/PrintPage/PrintPage";
import { routes } from "@/configs";
import GroupingView from "@/pages/GroupingView/GroupingView";
import useListGroupingQuery from "@/queries/useGroupingListQuery";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb } from "@/stores/breadcrumbs.store";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { useIntersectionObserver, useWindowSize } from "@uidotdev/usehooks";
import { Button, Card, Empty, Input, Space, Table } from "antd";
import { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AddSelectCompanyModal, { AddSelectCompanyModalRef, EnitityName } from "../AddSelectCompanyModal/AddSelectCompanyModal";

import "./GroupingTable.scss";
import { UserRole } from "@/dtos/user.dto";
interface Props {
  style?: React.CSSProperties;
}

const GroupingTable: FC<Props> = (props) => {
  const { style } = props;
  const dispatch = useAppDispatch();

  const { selectedCompanyId, selectedCompany } = useSelector((state: any) => state.company);

  const { navigate } = useCustomNavigate();

  const [columns, setColumns] = useState<any[]>([]);
  const [searchString, setSearchString] = useState<string | undefined>(undefined);
  const [searchStringValue, setSearchStringValue] = useState<string | undefined>("");

  const [groupingsDownRef, groupingsDownEntry] = useIntersectionObserver();

  const windowSize = useWindowSize();

  const {
    groupingList: groupings,
    isLoading,
    invalidateGroupings,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    totalItemCount,
  } = useListGroupingQuery({
    searchString: searchString,
  });

  useEffect(() => {
    if (groupingsDownEntry?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line
  }, [groupingsDownEntry?.time, groupingsDownEntry?.isIntersecting, hasNextPage]);

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [groupings]);

  const handleAddGrouping = () => {
    if (selectedCompany?._companyId === "all") {
      addModalRef.current?.open();
      return;
    } else {
      handleNavigateToAdd();
    }
  };

  const handleNavigateToAdd = () => {
    dispatch(
      addBreadcrumb({
        type: `Grouping / Add`,
        url: `grouping/add`,
      })
    );
    navigate(routes.groupingAdd);
  };

  const handleEditGrouping = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `${record?.name}`,
        url: `grouping/${record.id}/view`,
      })
    );
    navigate(routes.groupingView, { id: record.id });
  };

  const calculateColumns = () => {
    let tempColumns = [
      {
        title: "Grouping Name",
        key: "name",
        dataIndex: "name",
        fixed: "left",
        render: (val: any, record: any, index: any) => (
          <>
            <Button style={{ paddingLeft: 0, whiteSpace: "normal", wordBreak: "break-word", textAlign: "left" }} type="link" onClick={() => handleEditGrouping(record)}>
              {val}
            </Button>
            {index === groupings?.length - 1 ? <span ref={groupingsDownRef} /> : null}
          </>
        ),
      },
      {
        title: "Actions",
        key: "action",
        width: 250,
        align: "center",
        dataIndex: "",
        render: (value: any, record: any) => (
          <>
            <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleEditGrouping(record)}>
              View
            </Button>
          </>
        ),
      },
    ];

    if (!selectedCompanyId) {
      tempColumns.splice(1, 0, {
        title: "Company",
        key: "companyId",
        width: 250,
        align: "left",
        dataIndex: "companyId",
        render: (value: any, record: any) => <CompanyLabel companyId={value} />,
      });
    }

    setColumns(tempColumns);
  };

  const handleRefresh = () => {
    setSearchString("");
    setSearchStringValue("");
    invalidateGroupings();
  };

  const renderExpandData = (record: any) => {
    return <GroupingView overrideId={record.id} showHeader={false} />;
  };

  const addModalRef = useRef<AddSelectCompanyModalRef>(null);

  return (
    <>
      <Card
        styles={{
          body: {
            height: "calc(100vh - 320px)",
            padding: 0,
          },
        }}
        className="groupingTable"
        id="groupingTable"
        title={
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <span>Groupings</span>
            <Space direction="horizontal">
              <Space>
                <Input.Search
                  disabled={isLoading}
                  placeholder="Search groupings"
                  onSearch={(val) => {
                    setSearchString(val);
                  }}
                  onChange={(val) => setSearchStringValue(val.currentTarget.value)}
                  value={searchStringValue}
                  style={{ width: 400 }}
                  allowClear
                />
                <Button disabled={isLoading} onClick={handleRefresh} icon={<ReloadOutlined />}>
                  Refresh
                </Button>
              </Space>
            </Space>

            <span style={{ display: "flex", alignItems: "center" }}>
              <span style={{ paddingRight: 16 }}>Total: {totalItemCount ?? "-"}</span>
              {selectedCompany?.userRole === UserRole.admin.label || selectedCompany?._companyId === "all" ? (
                <>
                  <Button icon={<PlusOutlined />} onClick={handleAddGrouping} type="primary">
                    Add Grouping
                  </Button>
                </>
              ) : (
                <></>
              )}
            </span>
          </div>
        }
      >
        <PrintPage
          content={
            <div>
              <Table
                style={{
                  height: 400,
                }}
                className="customScrollBarStyle"
                pagination={false}
                rowKey={(row: any) => row.id}
                loading={isLoading || isFetchingNextPage}
                columns={columns}
                dataSource={groupings ?? []}
                scroll={{ y: windowSize?.height ? windowSize.height - 360 : 600 }}
                size="small"
                expandable={{
                  columnWidth: 55,
                  expandedRowRender: (record: any) => renderExpandData(record),
                }}
                locale={{
                  emptyText: <Empty description="No Groupings" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
                }}
              />
            </div>
          }
        />
      </Card>
      <AddSelectCompanyModal entityName={EnitityName.Grouping} ref={addModalRef} onSubmit={handleNavigateToAdd} />
    </>
  );
};

export default GroupingTable;
