import { Form, InputNumber, Modal } from "antd";
import React from "react";

type LateFeeModalProps = {
  onCancel: () => void;
  onOk: (percentage: number) => void;
};

export default function LateFeeModal({ onCancel, onOk }: LateFeeModalProps) {
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      onOk(values.percentage);
    });
  };

  return (
    <Modal open={true} title="Add Late Fee" onCancel={onCancel} onOk={handleSubmit}>
      <Form form={form} style={{ maxWidth: 600 }} initialValues={{ percentage: 18 }}>
        <Form.Item name="percentage" label="Percentage of Total" rules={[{ required: true }]}>
          <InputNumber min={0} style={{ width: "100%" }} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
