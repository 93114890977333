import { constants } from "@/configs";
import { get, post, put } from "@/services/request";

export const getProfile = () => {
  return get(`${constants.oidcConfig.authority}/api/user`);
};

export const listProfile = (request: any) => {
  return post(`${constants.oidcConfig.authority}/api/user/list`, request);
};

export const updateUserProfile = (data: any) => {
  return put(`${constants.oidcConfig.authority}/api/user/update`, data);
};

export const registerUser = (data: any) => {
  return post(`${constants.oidcConfig.authority}/api/registration`, data);
};

export const checkIfNeedToRegister = (email: string) => {
  return get(`${constants.oidcConfig.authority}/api/registration/needtoregister/${encodeURI(email)}`);
};

export const addNotBillableEntity = (request: any) => {
  return post(`${constants.oidcConfig.authority}/api/notbillableentity`, request);
};

export const updateNotBillableEntity = (request: any) => {
  return put(`${constants.oidcConfig.authority}/api/notbillableentity/update`, request);
};

export const getBillableStatus = (request: any) => {
  return post(`${constants.oidcConfig.authority}/api/notbillableentity/billable/status`, request);
};

export const verify2FaPhoneNumber = (request: any) => {
  return post(`${constants.oidcConfig.authority}/api/user/2fa/phonenumber/verify`, request);
};

export const remove2FaPhoneNumber = () => {
  return get(`${constants.oidcConfig.authority}/api/user/2fa/phonenumber/remove`);
};

export const enable2FaPhoneNumber = () => {
  return get(`${constants.oidcConfig.authority}/api/user/2fa/phonenumber/enable`);
};

export const resend2FaPhoneNumber = (request: any) => {
  return post(`${constants.oidcConfig.authority}/api/user/2fa/phonenumber/resend`, request);
};

export const enableUser = (userId: string) => {
  return put(`${constants.oidcConfig.authority}/api/user/${userId}/enable`, {});
};

export const disableUser = (userId: string) => {
  return put(`${constants.oidcConfig.authority}/api/user/${userId}/disable`, {});
};

export const confirmUserEmail = (userId: string) => {
  return put(`${constants.oidcConfig.authority}/api/user/${userId}/email/confirm`, {});
};
