import { LeftOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Descriptions, List, Space, Tag } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { getContactUs } from "@/apis/communication.api";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useCustomNavigate from "@/services/useCustomNavigate";
import dayjs from "dayjs";
import { useAppDispatch } from "@/stores";
import { removeBreadcrumb, replaceUrlStateBreadcrumbType } from "@/stores/breadcrumbs.store";
import { constants } from "@/configs";

const AdminContactUsView: FC = () => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();

  const { navigate } = useCustomNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [contactUsMessage, setContactUsMessage] = useState<any>(undefined);

  const pathUrl = window.location.pathname;

  useEffect(() => {
    if (pathUrl.includes("view") && contactUsMessage) {
      dispatch(replaceUrlStateBreadcrumbType(`Message - ${contactUsMessage?.email}`));
    }
    // eslint-disable-next-line
  }, [pathUrl, contactUsMessage]);

  useEffect(() => {
    if (id) getContactUsMessage();
    // eslint-disable-next-line
  }, [id]);

  const getContactUsMessage = async () => {
    setLoading(true);

    const response = await getContactUs(id);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setContactUsMessage(data.value);
      }
    }

    setLoading(false);
  };

  const renderActionButtons = (placement: TooltipPlacement) => (
    <Space>
      <Button
        loading={loading}
        icon={<LeftOutlined />}
        onClick={() => {
          dispatch(removeBreadcrumb());
          navigate(-1);
        }}
      >
        Back
      </Button>
    </Space>
  );

  const renderContent = () => {
    const data = [
      {
        title: "Message Log",
      },
    ];

    return (
      <div>
        <Descriptions
          bordered
          size="small"
          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          labelStyle={{ width: 200 }}
          style={{ width: 500, height: "100%" }}
          className="removeDescriptionHeaderPadding"
        >
          <Descriptions.Item label="Email">{contactUsMessage?.email}</Descriptions.Item>
          <Descriptions.Item label="Phone Number">{contactUsMessage?.phoneNumber}</Descriptions.Item>
          <Descriptions.Item label="First Name">{contactUsMessage?.firstName ?? "-"}</Descriptions.Item>
          <Descriptions.Item label="Last Name">{contactUsMessage?.lastName ?? "-"}</Descriptions.Item>
          <Descriptions.Item label="Promo Code">{contactUsMessage?.promoCode ?? "-"}</Descriptions.Item>
          <Descriptions.Item label="Status">{contactUsMessage?.status === "Open" ? <Tag color="blue">Open</Tag> : <Tag color="green">Closed</Tag>}</Descriptions.Item>
          <Descriptions.Item label="Closed Date">{contactUsMessage?.closedDate ? dayjs(contactUsMessage?.closedDate).format(constants.dateTimeFormat) : "-"}</Descriptions.Item>
        </Descriptions>
        <br />
        <Card title="Communication History">
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item, index) => (
              <List.Item style={{ whiteSpace: "pre-line" }}>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{
                        backgroundColor: "orange",
                        verticalAlign: "middle",
                      }}
                      size="large"
                    >
                      C
                    </Avatar>
                  }
                  title={item.title}
                  description={<>{`${contactUsMessage?.message}`}</>}
                />
              </List.Item>
            )}
          />
        </Card>
      </div>
    );
  };

  return (
    <Card className="adminContactUsMessageView" id="adminContactUsMessageView" title={`Contact Message - ${contactUsMessage?.email}`} extra={renderActionButtons("bottomRight")}>
      {renderContent()}
    </Card>
  );
};

export default AdminContactUsView;
