import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface ILookupsState {
  title: string;
  color: string;
  backendServiceStatus: any;
}

const initialState: ILookupsState = {
  title: "Disconnected",
  color: "red",
  backendServiceStatus: {
    well: "Disconnected",
    company: "Disconnected",
    farm: "Disconnected",
    field: "Disconnected",
    waterright: "Disconnected",
    lookup: "Disconnected",
    idsv: "Disconnected",
    restriction: "Disconnected",
    aggregator: "Disconnected",
    communication: "Disconnected",
    integration: "Disconnected",
  },
};

const serverStatus = createSlice({
  name: "serverStatus",
  initialState,
  reducers: {
    saveBackendServiceStatus: (state: ILookupsState, action: PayloadAction<any>) => {
      state.backendServiceStatus[action.payload.key] = action.payload.status;

      if (Object.values(state.backendServiceStatus).includes("Connecting")) {
        state.title = "Connecting";
        state.color = "orange";
        switch (action.payload) {
          case "Connected":
            break;
          case "Disconnected":
            state.title = "Disconnected";
            state.color = "red";
            break;
          case "Connecting":
        }
      } else if (Object.values(state.backendServiceStatus).every((x) => x === "Connected")) {
        state.title = "Connected";
        state.color = "green";
      }
    },
  },
});

export const { saveBackendServiceStatus } = serverStatus.actions;

export default serverStatus.reducer;
