import { addIntegrationConfig, rollIntegrationConfigEncryptionKey, updateIntegrationConfig } from "@/apis/integration.api";
import { constants, routes } from "@/configs";
import useIntegrationTemplateListQuery from "@/queries/useIntegrationTemplateListQuery";
import useIntegrationTemplateQuery from "@/queries/useIntegrationTemplateQuery";
import scrollToTop from "@/services/scrollToTop";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { removeBreadcrumb, removeViewEditBreadcrumbs } from "@/stores/breadcrumbs.store";
import { CloseOutlined, ReloadOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Divider, Form, Input, Select, Space, Steps, message } from "antd";
import { useWatch } from "antd/es/form/Form";
import { TooltipPlacement } from "antd/es/tooltip";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useIntegrationConfigQuery from "@/queries/useIntegrationConfigQuery";
import useGenerateIntegrationConfigEncryptionKey from "@/queries/useGenerateIntegrationConfigEncryptionKey";
import { encryptData } from "@/utils/rsaEncryption";
import { StatusTag } from "@/components";
import TextArea from "antd/es/input/TextArea";
import formatDateTime from "@/utils/formatDateTime";
import useIntegrationConfigListQuery from "@/queries/useIntegrationConfigListQuery";
import "./IntegrationAddEdit.scss";

const IntegrationAddEdit: FC = () => {
  const dispatch = useAppDispatch();

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const { navigate } = useCustomNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const templateId = useWatch((x) => x.templateId, form);

  const { invalidateIntegrationConfigs } = useIntegrationConfigListQuery({ searchString: undefined });
  const { integrationTemplateList, isLoading: isLoadingTemplates } = useIntegrationTemplateListQuery({ searchString: "" });
  const { integrationTemplate, isLoading: isLoadingTemplate } = useIntegrationTemplateQuery({ templateId });
  const { integrationConfig, isLoading: isLoadingIntegrationConfig, invalidateIntegrationConfig } = useIntegrationConfigQuery({ id });
  const { encryptionKey, isLoading: isLoadingEncryption, invalidateIntegrationConfigEncryptionKey } = useGenerateIntegrationConfigEncryptionKey({ id });

  useEffect(() => {
    if (integrationTemplateList && integrationConfig) {
      form.setFieldsValue({
        templateId: integrationConfig.templateId,
        configuration: integrationConfig.configuration,
      });
    }
  }, [integrationTemplateList, integrationConfig]);

  const onFinish = async (values: any, addAnother?: boolean) => {
    setLoading(true);

    const data = {
      ...values,
      configuration: {},
    };

    const publicKey = integrationConfig?.publicKey ?? encryptionKey?.publicKey;
    data.encryptionId = integrationConfig?.publicKey ? integrationConfig.encryptionId : encryptionKey?.id;

    if (!publicKey) {
      message.error(`Encryption key not found. Please generate a new encryption key and try again.`);
      setLoading(false);
      return;
    }

    // Use RSA encryption for secret fields
    for (const field of integrationTemplate.fields) {
      if (field.type === "secretstring" && values.configuration[field.map]) {
        try {
          const encryptedValue = await encryptData(publicKey, values.configuration[field.map]);
          data.configuration[field.map] = encryptedValue;
        } catch (error) {
          console.error(`Error encrypting field ${field.map}:`, error);
          message.error(`Failed to encrypt sensitive data. Please try again.`);
          setLoading(false);
          return;
        }
      } else {
        data.configuration[field.map] = values.configuration[field.map];
      }
    }

    if (id) {
      const response = await updateIntegrationConfig(id, data);
      if (response.ok) {
        message.success(`Successfully updated the integration data for ${integrationTemplate.name}`);
        navigate(routes.integrationList);
      } else {
        message.error(`Failed to update the integration data for ${integrationTemplate?.name}`);
      }
    } else {
      const response = await addIntegrationConfig(data);

      if (response.ok) {
        message.success(`Successfully updated the integration data for ${integrationTemplate?.name}`);
        if (addAnother) {
          invalidateIntegrationConfigs();
          invalidateIntegrationConfigEncryptionKey();
          form?.resetFields();
          navigate(routes.integrationAdd);
          scrollToTop();
        } else navigate(routes.integrationList);
      } else {
        message.error(`Failed to update the integration data for ${integrationTemplate?.name}`);
      }
    }

    if (id) {
      dispatch(removeViewEditBreadcrumbs());
    } else if (!addAnother) dispatch(removeBreadcrumb());

    invalidateIntegrationConfigEncryptionKey();
    setLoading(false);
  };

  const handleSubmit = (addAnother?: boolean) => {
    form
      ?.validateFields()
      .then((values) => {
        onFinish(values, addAnother);
      })
      .catch((errors) => {
        scrollToTop();
        message.error(`Please fill in the required fields`, 5);
      });
  };

  const handleAddAnother = () => {
    handleSubmit(true);
  };

  const handleGenerateNewEncryptionKey = async () => {
    setLoading(true);
    setTimeout(async () => {
      if (!id) {
        invalidateIntegrationConfigEncryptionKey();
      } else {
        const response = await rollIntegrationConfigEncryptionKey(id);
        if (response.ok) {
          message.success("Successfully rolled a new encryption key");
          invalidateIntegrationConfig();
        } else {
          message.error("Failed to roll a new encryption key");
        }
      }
      setTimeout(() => setLoading(false), 100);
    }, 50);
  };

  const renderActionButtons = (placement: TooltipPlacement) => (
    <Space>
      {!id && (
        <>
          <Button disabled={loading} loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={() => handleSubmit()}>
            Save
          </Button>
          <Button disabled={loading} loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={handleAddAnother}>
            Save + Add Another
          </Button>
          <Button
            disabled={loading}
            loading={loading}
            icon={<CloseOutlined />}
            onClick={() => {
              dispatch(removeBreadcrumb());
              navigate(routes.integrationList);
            }}
          >
            Cancel
          </Button>
        </>
      )}
      {id && (
        <>
          <Button disabled={loading} loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={() => handleSubmit()}>
            Save
          </Button>
          <Button
            disabled={loading}
            loading={loading}
            icon={<CloseOutlined />}
            onClick={() => {
              dispatch(removeBreadcrumb());
              navigate(-1);
            }}
          >
            Cancel
          </Button>
        </>
      )}
    </Space>
  );

  return (
    <Card id="integrationAddEdit" title={id ? "Edit Integration" : "Add Integration"} extra={renderActionButtons("bottomRight")} actions={[renderActionButtons("top")]} loading={isLoadingTemplates}>
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} onFinish={(values) => onFinish(values)} autoComplete="off" initialValues={integrationConfig}>
        <Steps
          direction="vertical"
          items={[
            {
              title: "Details",
              status: "process",
              description: (
                <Card>
                  <Form.Item label="Integration Type" name="templateId" rules={[{ required: true, message: "Please select an integration type" }]}>
                    <Select placeholder="Select a type" disabled={loading || isLoadingTemplates}>
                      {integrationTemplateList?.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Card>
              ),
            },
            integrationTemplate && {
              title: "Configuration",
              status: "process",
              description: (
                <Card loading={isLoadingTemplate}>
                  {integrationTemplate.fields.map((field: any) => (
                    <FormItem editing={!!id} field={field} loading={loading || isLoadingIntegrationConfig} />
                  ))}
                  <Divider />
                  <Form.Item label="Encryption">
                    <div style={{ paddingTop: 5 }}>
                      <StatusTag status={encryptionKey?.publicKey || integrationConfig?.publicKey ? "enabled" : "disabled"} />
                      <Button type="link" icon={<ReloadOutlined />} size="small" onClick={handleGenerateNewEncryptionKey} loading={isLoadingEncryption || loading}>
                        Generate New Key
                      </Button>
                      <br />
                      <small>Generated at: {formatDateTime(encryptionKey?.generatedAt ?? integrationConfig?.generatedAt, constants.dateTimeFormatWithSeconds)}</small>
                      <TextArea style={{ marginTop: 10 }} rows={10} readOnly autoSize value={encryptionKey?.publicKey ?? integrationConfig?.publicKey} />
                      <Alert
                        style={{ marginTop: 10 }}
                        message="Additional Information"
                        description={"This encryption key is used for end to end encryption of your sensitive configuration data."}
                        type="info"
                        showIcon
                      />
                    </div>
                  </Form.Item>
                </Card>
              ),
            },
          ]}
        />
      </Form>
    </Card>
  );
};

const FormItem: FC<any> = ({ editing, field, loading }) => (
  <Form.Item key={field.map} label={field.label} name={["configuration", field.map]} rules={[{ required: field.required, message: field.requiredMessage }]}>
    {renderField(editing, field, loading)}
  </Form.Item>
);

const renderField = (editing: boolean, field: any, loading: boolean) => {
  switch (field.type) {
    case "string":
      return <Input placeholder={field.placeholderMessage} disabled={loading} />;
    case "secretstring":
      return <Input.Password placeholder={editing ? "**********" : field.placeholderMessage} disabled={loading} autoComplete="none" />;
    case "number":
      return <Input type="number" placeholder={field.placeholderMessage} disabled={loading} />;
    case "select":
      return (
        <Select placeholder={field.placeholderMessage} disabled={loading}>
          {field.options.map((option: any) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      );
    default:
      return <Input placeholder={field.placeholderMessage} disabled={loading} />;
  }
};

export default IntegrationAddEdit;
