import { getIntegrationTemplates } from "@/apis/integration.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";

type useIntegrationTemplateListQueryProps = {
  searchString?: string;
};

const useIntegrationTemplateListQuery = ({ searchString }: useIntegrationTemplateListQueryProps) => {
  const queryClient = useQueryClient();

  const {
    data: integrationTemplateList,
    isFetching,
    isFetched,
    isLoading,
  } = useQuery({
    queryKey: [
      CacheKeys.integrations,
      CacheKeys.template,
      CacheKeys.list,
      {
        searchString: searchString,
      },
    ],
    queryFn: async () => {
      const response = await getIntegrationTemplates({
        searchString: searchString,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch integration templates");
    },
    ...defaultQueryConfig,
    gcTime: Infinity,
    staleTime: Infinity,
  });

  const invalidateIntegrationTemplates = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.integrations, CacheKeys.template, CacheKeys.list],
    });
  }, [queryClient]);

  const totalItemCount = useMemo(() => integrationTemplateList?.length ?? 0, [integrationTemplateList?.length]);

  return {
    integrationTemplateList,
    isLoading,
    invalidateIntegrationTemplates,
    isFetching,
    isFetched,
    totalItemCount,
  };
};

export default useIntegrationTemplateListQuery;
