const routes = {
  home: { path: "/" },
  contactUs: { path: "/contact-us" },
  aboutUs: { path: "/about-us" },
  faqs: { path: "/faqs" },
  photoGallery: { path: "/photo-gallery" },
  missionVision: { path: "/mission-vision" },
  ethicsSustainability: { path: "/ethics-sustainability" },
  dashboard: { path: "/dashboard" },
  privacy: { path: "/privacy-policy" },
  termsOfUse: { path: "/terms-of-use" },
  companyAdd: { path: "/company/add" },
  companyEdit: { path: "/company/:id/edit" },
  companyList: { path: "/company/list" },
  companyView: { path: "/company/:id/view" },
  lookups: { path: "/admin/lookups" },
  fieldList: { path: "/field/list" },
  fieldAdd: { path: "/field/add" },
  fieldEdit: { path: "/field/:id/edit" },
  fieldView: { path: "/field/:id/view" },
  groupingList: { path: "/grouping/list" },
  groupingAdd: { path: "/grouping/add" },
  groupingEdit: { path: "/grouping/:id/edit" },
  groupingView: { path: "/grouping/:id/view" },
  stockList: { path: "/stock/list" },
  stockAdd: { path: "/stock/add" },
  stockEdit: { path: "/stock/:id/edit" },
  stockView: { path: "/stock/:id/view" },
  wellList: { path: "/well/list" },
  wellAdd: { path: "/well/add" },
  wellEdit: { path: "/well/:id/edit" },
  wellView: { path: "/well/:id/view" },
  waterRightList: { path: "/waterright/list" },
  waterRightAdd: { path: "/waterright/add" },
  waterRightEdit: { path: "/waterright/:id/edit" },
  waterRightView: { path: "/waterright/:id/view" },
  userList: { path: "/user/list" },
  userAdd: { path: "/user/add" },
  // userAdd: { path: "/user/add", requiredAccess: [UserRole.admin.label] },
  userEdit: { path: "/user/:id/edit" },
  // userEdit: { path: "/user/:id/edit", requiredAccess: [UserRole.admin.label] },

  integrationList: { path: "/integration/list" },
  integrationAdd: { path: "/integration/add" },
  integrationEdit: { path: "/integration/:id/edit" },
  integrationView: { path: "/integration/:id/view" },

  reportList: { path: "/report/list" },
  restrictionList: { path: "/restriction/list" },
  restrictionAdd: { path: "/restriction/add" },
  restrictionEdit: { path: "/restriction/:id/edit" },
  restrictionView: { path: "/restriction/:id/view" },
  placeOfUseList: { path: "/placesofuse/list" },
  placeOfUseAdd: { path: "/placesofuse/add" },
  placeOfUseEdit: { path: "/placesofuse/:id/edit" },
  acceptCompanyInvite: { path: "/invite/:inviteId/accept" },
  notification: { path: "/notification" },
  adminDashboard: { path: "/admin/dashboard" },
  adminReports: { path: "/admin/reports" },
  adminUsers: { path: "/admin/users" },
  adminCommunication: { path: "/admin/communication" },
  adminCommunicationAdd: { path: "/admin/communication/add" },
  adminCommunicationEdit: { path: "/admin/communication/:id/edit" },
  adminCommunicationView: { path: "/admin/communication/:id/view" },
  adminFaqsList: { path: "/admin/faqs" },
  adminFaqsAdd: { path: "/admin/faqs/add" },
  adminFaqsEdit: { path: "/admin/faqs/:id/edit" },
  adminSupportQueryList: { path: "/admin/supportquery/list" },
  adminSupportQueryView: { path: "/admin/supportquery/:id/view" },
  adminContactUsList: { path: "/admin/contactus/list" },
  adminContactUsView: { path: "/admin/contactus/:id/view" },
  adminBilling: { path: "/admin/billing" },
  adminEvents: { path: "/admin/events" },
  adminIntegrations: { path: "/admin/integrations" },

  mobile: {
    privacy: { path: "/mobile/privacy-policy" },
    termsOfUse: { path: "/mobile/terms-of-use" },
    register: { path: "/mobile/register" },
  },
};

export default routes;
