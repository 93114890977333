import { CheckCircleOutlined, MinusCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Popconfirm, Input, Space, Table, message } from "antd";
import { confirmUserEmail, disableUser, enableUser, listProfile } from "@/apis/identity.api";
import { DeviceTable, StatusTag } from "@/components";
import { FC, useEffect, useState } from "react";
import { formatPhoneNumber } from "@/services/utils";

const AdminUsersTable: FC = () => {
  const [users, setUsers] = useState<any[]>([]);

  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string | undefined>(undefined);
  const [loadingEnableDisableUser, setLoadingEnableDisableUser] = useState<boolean>(false);

  const [userColumns, setUserColumns] = useState<any[]>([]);

  useEffect(() => {
    refreshUsersList();
  }, []);

  useEffect(() => {
    calculateUsersColumns();
  }, [users]);

  const refreshUsersList = async (searchString: any = null) => {
    setLoadingUsers(true);

    const request = { searchString };
    const response = await listProfile(request);

    if (response.ok) {
      const data = await response.json();
      setUsers(data);
    }
    setLoadingUsers(false);
  };

  const handleEnableDisableUser = async (record: any) => {
    setLoadingEnableDisableUser(true);

    if (record.lockoutEnd) {
      const response = await enableUser(record.id);
      if (response.ok) {
        message.success(`Successfuly enabled user ${record?.firstName} ${record?.lastName}`);
        refreshUsersList();
      } else {
        message.error(`Failed to enable user ${record?.firstName} ${record?.lastName}`);
      }
    } else {
      const response = await disableUser(record.id);
      if (response.ok) {
        message.success(`Successfuly disabled user ${record?.firstName} ${record?.lastName}`);
        refreshUsersList();
      } else {
        message.error(`Failed to disable user ${record?.firstName} ${record?.lastName}`);
      }
    }
    setLoadingEnableDisableUser(false);
  };

  const manuallyConfirmEmailAddress = async (record: any) => {
    const response = await confirmUserEmail(record.id);
    if (response.ok) {
      message.success(`Successfuly confirmed the email account for user ${record?.email}`);
      refreshUsersList();
    } else {
      message.error(`Failed to confirm the email account for user ${record?.email}`);
    }
  };

  const calculateUsersColumns = () => {
    let tempColumns: any[] = [
      {
        title: "First Name",
        key: "firstName",
        dataIndex: "firstName",
        render: (val: any, record: any) => val,
      },
      {
        title: "Last Name",
        key: "lastName",
        dataIndex: "lastName",
        render: (val: any, record: any) => val,
      },
      {
        title: "Email",
        key: "email",
        dataIndex: "email",
        render: (val: any, record: any) => (
          <>
            {record.emailConfirmed ? <CheckCircleOutlined style={{ color: "green" }} /> : <MinusCircleOutlined style={{ color: "red" }} />} {val?.toLowerCase()}
          </>
        ),
      },
      {
        title: "Phone Number",
        key: "phoneNumber",
        dataIndex: "phoneNumber",
        render: (val: any, record: any) => (val ? formatPhoneNumber(val) : "-"),
      },
      {
        title: "Enabled",
        key: "lockoutEnd",
        dataIndex: "lockoutEnd",
        width: 120,
        render: (val: any, record: any) => <StatusTag status={val ? "disabled" : "enabled"} />,
      },
      {
        title: "Actions",
        key: "actions",
        width: 210,
        render: (val: any, record: any) => (
          <>
            <Popconfirm
              title={`${record.lockoutEnd ? "Enable" : "Disable"} user`}
              description={`Are you sure you want to ${record.lockoutEnd ? "enable" : "disable"} the user?`}
              onConfirm={() => handleEnableDisableUser(record)}
              okText="Yes"
              cancelText="No"
              placement="topRight"
            >
              <Button loading={loadingEnableDisableUser} disabled={loadingUsers || loadingEnableDisableUser} style={{ paddingLeft: 0, paddingRight: 0 }} type="link">
                {record.lockoutEnd ? "Enable" : "Disable"}
              </Button>
            </Popconfirm>
            {!record.emailConfirmed && " | "}
            {!record.emailConfirmed && (
              <Popconfirm
                title="Confirm email address"
                description="Are you sure you want to enable the account by confirming the email address is correct?"
                onConfirm={() => manuallyConfirmEmailAddress(record)}
                okText="Yes"
                cancelText="No"
                placement="topRight"
              >
                <Button loading={loadingEnableDisableUser} disabled={loadingUsers || loadingEnableDisableUser} style={{ paddingLeft: 0, paddingRight: 0 }} type="link">
                  Manually Confirm
                </Button>
              </Popconfirm>
            )}
          </>
        ),
      },
    ];

    setUserColumns(tempColumns);
  };

  const onSearchChange = (searchString: any) => {
    setSearchString(searchString);
  };

  const onSearch = (searchString: any) => {
    setSearchString(searchString);
    refreshUsersList(searchString);
  };

  const handleRefresh = () => {
    setSearchString(undefined);
    refreshUsersList();
  };

  const renderExpandData = (record: any) => {
    return (
      <div style={{ padding: 10, paddingTop: 0, paddingRight: 50 }}>
        <div style={{ paddingLeft: 10 }}>
          <Divider orientation="left">Devices</Divider>
        </div>
        <DeviceTable userId={record.id} />
      </div>
    );
  };

  return (
    <Card className="adminUsersList" id="adminUsersList" title="Users">
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space style={{ paddingBottom: 10 }}>
          <Input.Search
            disabled={loadingUsers}
            placeholder="Search users"
            onSearch={(searchString) => {
              onSearch(searchString);
            }}
            onChange={(val) => onSearchChange(val.currentTarget.value)}
            value={searchString}
            style={{ width: 400 }}
            allowClear
          />
          <Button disabled={loadingUsers} icon={<ReloadOutlined />} onClick={handleRefresh}>
            Refresh
          </Button>
        </Space>
      </Space>
      <Table
        rowKey={(row: any) => row.id}
        loading={loadingUsers}
        columns={userColumns}
        dataSource={users || []}
        size="small"
        expandable={{
          expandedRowRender: (record: any) => renderExpandData(record),
        }}
      />
    </Card>
  );
};

export default AdminUsersTable;
