import { Card, Divider, List, Typography } from "antd";
import { FC, useEffect, useState } from "react";

type FilterCardProps = {
  isDue: boolean;
  warningQuantityThreshold: boolean;
};

const MapFilterCard: FC<FilterCardProps> = ({ isDue, warningQuantityThreshold }) => {
  const [items, setItems] = useState<string[]>([]);

  useEffect(() => {
    const activeFilters = [];
    if (isDue) activeFilters.push("Wells/Meters Due For Collection");
    if (warningQuantityThreshold) activeFilters.push("Wells/Meters Below Warning Quantity");

    setItems(activeFilters);
  }, [isDue, warningQuantityThreshold]);

  return (
    <Card
      style={{ padding: 0, margin: 0 }}
      styles={{
        body: {
          padding: 0,
          margin: 0,
          width: 200,
          overflow: "hidden",
        },
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", cursor: "pointer", paddingLeft: 10, paddingTop: 5 }}>
        <Typography.Title level={5}>Additional Filters</Typography.Title>
      </div>
      <Divider style={{ margin: 0 }} />
      <List size="small" dataSource={items} renderItem={(item) => <List.Item>{item}</List.Item>} style={{ maxHeight: 400, overflow: "auto" }} />
    </Card>
  );
};

export default MapFilterCard;
