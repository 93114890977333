import { constants, routes } from "@/configs";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb, removeBreadcrumb, replaceUrlStateBreadcrumbType } from "@/stores/breadcrumbs.store";
import { EditOutlined, LeftOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Col, Descriptions, Divider, message, Row, Tabs } from "antd";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useIntegrationTemplateQuery from "@/queries/useIntegrationTemplateQuery";
import useIntegrationConfigQuery from "@/queries/useIntegrationConfigQuery";
import { StatusTag } from "@/components";
import { rollIntegrationConfigEncryptionKey } from "@/apis/integration.api";
import formatDateTime from "@/utils/formatDateTime";
import "./IntegrationView.scss";

interface GroupingViewProps {
  showHeader?: boolean;
  overrideId?: string;
}

const IntegrationView: FC<GroupingViewProps> = (props) => {
  const { showHeader = true, overrideId = undefined } = props;

  const dispatch = useAppDispatch();

  const { navigate } = useCustomNavigate();
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState<boolean>(false);

  const pathUrl = window.location.pathname;

  const { integrationConfig, isLoading: isLoadingIntegrationConfig, invalidateIntegrationConfig } = useIntegrationConfigQuery({ id: overrideId ?? id });
  const { integrationTemplate, isLoading: isLoadingTemplate, invalidateIntegrationTemplates } = useIntegrationTemplateQuery({ templateId: integrationConfig?.templateId });

  useEffect(() => {
    refreshIntegrationData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (pathUrl.includes("view") && integrationTemplate) {
      dispatch(replaceUrlStateBreadcrumbType(integrationTemplate?.name));
    }
  }, [pathUrl, integrationTemplate]);

  const refreshIntegrationData = async () => {
    invalidateIntegrationConfig();
    invalidateIntegrationTemplates();
  };

  const navigateEdit = () => {
    dispatch(
      addBreadcrumb({
        type: `Edit`,
        url: `integration/${id}/edit`,
      })
    );
    navigate(routes.integrationEdit, { id: overrideId ?? id });
  };

  const navigateBack = () => {
    dispatch(removeBreadcrumb());
    navigate(-1);
  };

  const handleGenerateNewEncryptionKey = async () => {
    setLoading(true);

    setTimeout(async () => {
      const response = await rollIntegrationConfigEncryptionKey(overrideId ?? (id as string));
      if (response.ok) {
        message.success("Successfully rolled a new encryption key");
        invalidateIntegrationConfig();
      } else {
        message.error("Failed to roll a new encryption key");
      }
      setTimeout(() => setLoading(false), 100);
    });
  };

  return (
    <Card
      className="integrationView"
      loading={isLoadingIntegrationConfig || isLoadingTemplate}
      id="integrationView"
      title={showHeader ? `Integration - ${isLoadingTemplate ? "loading..." : integrationTemplate?.name}` : undefined}
      extra={
        showHeader ? (
          <>
            <Button icon={<LeftOutlined />} style={{ marginRight: 5 }} onClick={navigateBack}>
              Back
            </Button>
            <Button icon={<EditOutlined />} type="primary" onClick={navigateEdit}>
              Edit
            </Button>
          </>
        ) : undefined
      }
    >
      <Descriptions style={{ paddingLeft: 0, width: 600 }} bordered size="small" labelStyle={{ width: 200 }} column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
        <Descriptions.Item label="Name">{integrationTemplate?.name}</Descriptions.Item>
        <Descriptions.Item label="Last Synced">{integrationConfig?.lastSynchronized ? dayjs(integrationConfig?.lastSynchronized).format(constants.dateTimeFormat) : "Never"}</Descriptions.Item>
      </Descriptions>
      <br />
      <Tabs
        defaultActiveKey="1"
        type="card"
        size="small"
        style={{ marginBottom: 32 }}
        items={[
          {
            label: "Details",
            key: "details",
            children: (
              <Row gutter={[16, 16]}>
                <Col>
                  <Descriptions bordered size="small" column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }} labelStyle={{ width: 165 }} style={{ width: "100%", height: "100%" }}>
                    {integrationTemplate?.fields?.map((field: any) => {
                      return (
                        <Descriptions.Item key={field.map} label={field.label}>
                          {field.type === "secretstring" ? "**********" : integrationConfig?.configuration[field.map]}
                        </Descriptions.Item>
                      );
                    })}
                    <Descriptions.Item key={"encryption"} label={"Encryption"}>
                      <div style={{ paddingTop: 5 }}>
                        <StatusTag status={integrationConfig?.publicKey ? "enabled" : "disabled"} />
                        <Button type="link" size="small" icon={<ReloadOutlined />} onClick={handleGenerateNewEncryptionKey} loading={isLoadingIntegrationConfig || loading}>
                          Generate New Key
                        </Button>
                        <br />
                        <small>Generated at: {formatDateTime(integrationConfig?.generatedAt, constants.dateTimeFormatWithSeconds)}</small>
                        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                        <pre style={{ margin: 0, fontFamily: "inherit" }}>
                          <small>{integrationConfig?.publicKey}</small>
                        </pre>
                      </div>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            ),
          },
          // {
          //   label: "Audit Log",
          //   key: "auditLog",
          //   children: <AuditLogTable entityId={integrationData?.id} entityType={AuditLogType.IntegrationConfig} />,
          // },
        ]}
      />
    </Card>
  );
};

export default IntegrationView;
