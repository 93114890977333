import { useLocalStorage } from "@uidotdev/usehooks";
import { useEffect } from "react";
import useFeatureFlag, { FeatureFlagType } from "./useFeatureFlag";

export type DashboardOrder = {
  label: string;
  value: string;
  default: boolean;
};

const dashboardOrderCurrentVersion = "2025/02/17";

const defaultOrder = [
  { label: "Analytics", value: "1", default: false },
  { label: "Operations", value: "2", default: true },
  { label: "Gauges", value: "3", default: false },
  { label: "Map", value: "4", default: false },
  { label: "Actions", value: "5", default: false },
];

const useDashboardOrders = () => {
  const [dashboardOrderVersion, setDashboardOrderVersion] = useLocalStorage<string>("dashboardOrderVersion", dashboardOrderCurrentVersion);
  const [dashboardOrders, setDashboardOrders] = useLocalStorage<DashboardOrder[]>("dashboardOrder", defaultOrder);

  const { hasFeature: hasAnalyticsDashboard } = useFeatureFlag(FeatureFlagType.ANALYTICS_DASHBOARD);

  useEffect(() => {
    if (!dashboardOrderVersion || dashboardOrderVersion !== dashboardOrderCurrentVersion) {
      setDashboardOrderVersion(dashboardOrderCurrentVersion);
      setDashboardOrders(defaultOrder);
    } else {
      const defaultOrderValues = defaultOrder.map((item) => item.value);
      const storedOrderValues = dashboardOrders.map((item) => item.value);
      const missingDefaultOrderValues = defaultOrderValues.filter((value) => !storedOrderValues.includes(value));
      if (missingDefaultOrderValues.length > 0) {
        setDashboardOrders([...dashboardOrders, ...defaultOrder.filter((item) => missingDefaultOrderValues.includes(item.value))]);
      }
    }
  }, [dashboardOrders, dashboardOrderVersion, setDashboardOrderVersion, setDashboardOrders]);

  if (!dashboardOrderVersion || dashboardOrderVersion !== dashboardOrderCurrentVersion) {
    return {
      dashboardOrders: hasAnalyticsDashboard ? defaultOrder : defaultOrder.filter((order) => order.value !== "1"),
      setDashboardOrders,
    };
  }

  return {
    dashboardOrders: hasAnalyticsDashboard ? dashboardOrders : dashboardOrders.filter((order) => order.value !== "1"),
    setDashboardOrders,
  };
};

export default useDashboardOrders;
