import CompanyLabel from "@/components/Lookups/CompanyLabel";
import PrintPage from "@/components/PrintPage/PrintPage";
import StatusTag from "@/components/StatusTag/StatusTag";
import { routes } from "@/configs";
import { UserRole } from "@/dtos/user.dto";
import FieldView from "@/pages/FieldView/FieldView";
import useListFieldQuery from "@/queries/useListFieldQuery";
import useCustomNavigate from "@/services/useCustomNavigate";
import { RootState, useAppDispatch } from "@/stores";
import { addBreadcrumb } from "@/stores/breadcrumbs.store";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { useIntersectionObserver, useWindowSize } from "@uidotdev/usehooks";
import { Button, Card, Empty, Input, Space, Switch, Table } from "antd";
import { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AddSelectCompanyModal, { AddSelectCompanyModalRef, EnitityName } from "../AddSelectCompanyModal/AddSelectCompanyModal";

import "./FieldTable.scss";
interface Props {
  style?: React.CSSProperties;
  fieldIds?: any[];
  showAddField: boolean;
  trackTableState?: boolean;
}

const FieldTable: FC<Props> = (props) => {
  const { style, fieldIds, showAddField, trackTableState = false } = props;

  const [fieldsDownRef, fieldsDownEntry] = useIntersectionObserver();

  const dispatch = useAppDispatch();

  const { selectedCompanyId, selectedCompany } = useSelector((state: RootState) => state.company);

  const { navigate } = useCustomNavigate();

  const [isActive, setIsActive] = useState<boolean | null>(true);
  const [columns, setColumns] = useState<any[]>([]);
  const [searchStringValue, setSearchStringValue] = useState<string | undefined>("");
  const [searchString, setSearchString] = useState<string | undefined>("");

  const windowSize = useWindowSize();

  const {
    fieldList: fields,
    isLoading,
    invalidateFields,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    totalItemCount,
  } = useListFieldQuery({
    fieldIds,
    isActive,
    searchString,
  });

  useEffect(() => {
    if (fieldsDownEntry?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line
  }, [fieldsDownEntry?.time, fieldsDownEntry?.isIntersecting, hasNextPage]);

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [fields]);

  const calculateColumns = () => {
    let tempColumns = [
      {
        title: "Field Name",
        key: "name",
        dataIndex: "name",
        fixed: "left",
        width: 500,
        render: (val: any, record: any, index: any) => (
          <>
            <Button style={{ paddingLeft: 0, whiteSpace: "normal", wordBreak: "break-word", textAlign: "left" }} type="link" onClick={() => handleViewField(record)}>
              {val}
            </Button>
            {index === fields?.length - 1 ? <span ref={fieldsDownRef} /> : null}
          </>
        ),
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (val: any, record: any) => <StatusTag status={val} />,
      },
      {
        title: "Actions",
        key: "action",
        width: 80,
        align: "center",
        render: (value: any, record: any) => (
          <>
            <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleViewField(record)}>
              View
            </Button>
          </>
        ),
      },
    ];

    if (!selectedCompanyId) {
      tempColumns.splice(2, 0, {
        title: "Company",
        key: "companyId",
        dataIndex: "companyId",
        render: (val: any, record: any) => <CompanyLabel companyId={val} />,
      });
    }

    setColumns(tempColumns);
  };

  const handleNavigateToAdd = () => {
    dispatch(
      addBreadcrumb({
        type: `Field / Add`,
        url: `field/add`,
      })
    );
    navigate(routes.fieldAdd);
  };

  const handleAddField = () => {
    if (selectedCompany?._companyId === "all") {
      addModalRef.current?.open();
      return;
    } else {
      handleNavigateToAdd();
    }
  };

  const handleViewField = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `${record.name}`,
        url: `field/${record.id}/view`,
      })
    );
    navigate(routes.fieldView, { id: record.id });
  };

  const handleRefresh = () => {
    setSearchString("");
    setSearchStringValue("");
    invalidateFields();
  };

  const renderExpandData = (record: any) => {
    return <FieldView showHeader={false} overrideId={record.id} />;
  };

  const handleOnChange = (value: boolean) => {
    if (!value) setIsActive(null);
    else setIsActive(true);
  };

  const addModalRef = useRef<AddSelectCompanyModalRef>(null);

  const renderContent = () => {
    return (
      <div>
        <Table
          className="customScrollBarStyle"
          pagination={false}
          rowKey={(row: any) => row.id}
          loading={isLoading || isFetchingNextPage}
          columns={columns}
          dataSource={fields ?? []}
          scroll={{ y: windowSize?.height ? windowSize.height - 360 : 600 }}
          // style={{
          //   height: 400,
          // }}
          size="small"
          expandable={{
            columnWidth: 55,
            expandedRowRender: (record: any) => renderExpandData(record),
          }}
          locale={{
            emptyText: <Empty description="No Fields" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
          }}
        />
      </div>
    );
  };

  return (
    <>
      <Card
        styles={{
          body: {
            height: fieldIds ? "calc(100vh - 620px)" : "calc(100vh - 320px)",
            padding: 0,
          },
        }}
        className="fieldList"
        id="fieldList"
        title={
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <span>Fields</span>
            <Space direction="horizontal">
              <Space>
                <Input.Search
                  disabled={isLoading}
                  placeholder="Search fields"
                  onSearch={(val) => {
                    setSearchString(val);
                  }}
                  onChange={(val) => setSearchStringValue(val.currentTarget.value)}
                  value={searchStringValue}
                  style={{ width: 400 }}
                  allowClear
                />
                <Button icon={<ReloadOutlined />} disabled={isLoading} onClick={handleRefresh}>
                  Refresh
                </Button>
                <Switch defaultChecked onChange={handleOnChange} checkedChildren="Active Only" unCheckedChildren="All" />
              </Space>
            </Space>

            <span style={{ display: "flex", alignItems: "center" }}>
              <span style={{ paddingRight: 16 }}>Total: {totalItemCount ?? "-"}</span>
              {showAddField && (selectedCompany?.userRole === UserRole.admin.label || selectedCompany?._companyId === "all") ? (
                <>
                  <Button onClick={handleAddField} type="primary" icon={<PlusOutlined />}>
                    Add Field
                  </Button>
                </>
              ) : (
                <></>
              )}
            </span>
          </div>
        }
      >
        {showAddField ? <PrintPage content={renderContent()} /> : renderContent()}
      </Card>
      <AddSelectCompanyModal entityName={EnitityName.Field} ref={addModalRef} onSubmit={handleNavigateToAdd} />
    </>
  );
};

export default FieldTable;
