import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Modal, Select, Space, Switch } from "antd";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface Props {
  open: boolean;
  onSuccess: (values: string[], isDue: boolean, warningThreshold: boolean) => void;
  onCancel: () => void;
  isDue: boolean;
  warningQuantityThreshold: boolean;
  permitTypes: string[];
}

const LookupFilterModal: FC<Props> = (props: Props) => {
  let { open, permitTypes, isDue, warningQuantityThreshold, onSuccess, onCancel } = props;

  const { lookups } = useSelector((state: any) => state.lookups);

  const [form] = Form.useForm();

  const [permitTypeLookups, setPermitTypeLookups] = useState<any[]>([]);

  const [selectOpen, setSelectOpen] = useState(false);

  useEffect(() => {
    form.setFieldValue("permitTypes", permitTypes);
    form.setFieldValue("isDue", isDue);
    form.setFieldValue("warningQuantityThreshold", warningQuantityThreshold);
  }, [permitTypes, isDue, warningQuantityThreshold]);

  useEffect(() => {
    if (lookups) {
      const permitTypeLookups = lookups.find((lookup: any) => lookup.map === "permittype");
      setPermitTypeLookups(
        permitTypeLookups?.options?.map((permitType: any) => {
          return {
            name: permitType.code + " - " + permitType.name,
            value: permitType.value,
          };
        })
      );
    }
  }, [lookups]);

  const renderOptions = () => {
    return permitTypeLookups?.map((lookup: any) => (
      <Select.Option value={lookup.value} key={lookup.value} label={lookup.name}>
        {lookup.name}
      </Select.Option>
    ));
  };

  const handleSubmit = () => {
    onSuccess && onSuccess(form.getFieldValue("permitTypes"), form.getFieldValue("isDue"), form.getFieldValue("warningQuantityThreshold"));
  };

  return (
    <Modal
      centered
      maskClosable={false}
      open={open}
      okText="Filter"
      onOk={handleSubmit}
      okButtonProps={{
        icon: <ThunderboltOutlined />,
      }}
      cancelText="Cancel"
      onCancel={onCancel}
      cancelButtonProps={{ icon: <CloseOutlined /> }}
      width={600}
      closable={false}
      title="Filter wells on map"
    >
      <Form id="lookupFilterModal" form={form} labelAlign="left" labelCol={{ span: 11 }} wrapperCol={{ span: 13 }} layout="horizontal">
        <Form.Item name="permitTypes" label="Permit Type">
          <Select
            id="selectClick"
            open={selectOpen}
            optionFilterProp="label"
            allowClear
            maxTagCount={1}
            mode="multiple"
            placeholder={"Select permit types to filter wells"}
            onClick={(e: any) => {
              if (e.target.id !== "buttonClick") setSelectOpen(true);
            }}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Button
                    onClick={() =>
                      form.setFieldValue(
                        "permitTypes",
                        permitTypeLookups.filter((item: any) => item !== undefined).map((lookup: any) => lookup.value)
                      )
                    }
                  >
                    Select All
                  </Button>
                  <Button danger onClick={() => form.setFieldValue("permitTypes", [])}>
                    Clear List
                  </Button>
                </Space>
                <Button id="buttonClick" style={{ float: "right" }} onClick={() => setTimeout(() => setSelectOpen(false), 50)}>
                  OK
                </Button>
              </>
            )}
          >
            {renderOptions()}
          </Select>
        </Form.Item>
        <Form.Item name="isDue" label="Wells/Meters Due For Collection">
          <Switch />
        </Form.Item>
        <Form.Item name="warningQuantityThreshold" label="Wells/Meters Below Warning Quantity">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default LookupFilterModal;
