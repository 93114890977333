import CompanyLabel from "@/components/Lookups/CompanyLabel";
import PrintPage from "@/components/PrintPage/PrintPage";
import { routes } from "@/configs";
import { UserRole } from "@/dtos/user.dto";
import { StockView } from "@/pages";
import useListStockQuery from "@/queries/useListStockQuery";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb } from "@/stores/breadcrumbs.store";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { useIntersectionObserver, useWindowSize } from "@uidotdev/usehooks";
import { Button, Card, Empty, Input, Space, Table } from "antd";
import { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AddSelectCompanyModal, { AddSelectCompanyModalRef, EnitityName } from "../AddSelectCompanyModal/AddSelectCompanyModal";

const StockTable: FC = () => {
  const dispatch = useAppDispatch();

  const { selectedCompanyId, selectedCompany } = useSelector((state: any) => state.company);

  const { navigate } = useCustomNavigate();

  const [columns, setColumns] = useState<any[]>([]);
  const [searchStringValue, setSearchStringValue] = useState<string | undefined>("");
  const [searchString, setSearchString] = useState<string | undefined>("");

  const [stocksDownRef, stocksDownEntry] = useIntersectionObserver();

  const windowSize = useWindowSize();

  const {
    stockList: stocks,
    isLoading,
    invalidateStocks,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    totalItemCount,
  } = useListStockQuery({
    searchString,
  });

  useEffect(() => {
    if (stocksDownEntry?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line
  }, [stocksDownEntry?.time, stocksDownEntry?.isIntersecting, hasNextPage]);

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [stocks]);

  const handleRefresh = () => {
    setSearchString("");
    setSearchStringValue("");
    invalidateStocks();
  };

  const handleAddStock = () => {
    if (selectedCompany?._companyId === "all") {
      addModalRef.current?.open();
      return;
    } else {
      handleNavigateToAdd();
    }
  };

  const handleNavigateToAdd = () => {
    dispatch(
      addBreadcrumb({
        type: `Stock / Add`,
        url: `stock/add`,
      })
    );
    navigate(routes.stockAdd);
  };

  const handleEditStock = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `${record?.name}`,
        url: `stock/${record.id}/view`,
      })
    );
    navigate(routes.stockView, { id: record.id });
  };

  const calculateColumns = () => {
    let tempColumns = [
      {
        title: "Stock Name",
        key: "name",
        dataIndex: "name",
        fixed: "left",
        render: (val: any, record: any, index: any) => (
          <>
            <Button style={{ paddingLeft: 0, whiteSpace: "normal", wordBreak: "break-word", textAlign: "left" }} type="link" onClick={() => handleEditStock(record)}>
              {val}
            </Button>
            {index === stocks?.length - 1 ? <span ref={stocksDownRef} /> : null}
          </>
        ),
      },
      {
        title: "Actions",
        key: "action",
        width: 250,
        align: "center",
        dataIndex: "",
        render: (value: any, record: any) => (
          <>
            <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleEditStock(record)}>
              View
            </Button>
          </>
        ),
      },
    ];

    if (!selectedCompanyId) {
      tempColumns.splice(1, 0, {
        title: "Company",
        key: "companyId",
        width: 250,
        align: "left",
        dataIndex: "companyId",
        render: (value: any, record: any) => <CompanyLabel companyId={value} />,
      });
    }

    setColumns(tempColumns);
  };

  const renderExpandData = (record: any) => {
    return <StockView overrideId={record.id} showHeader={false} />;
  };

  const addModalRef = useRef<AddSelectCompanyModalRef>(null);

  const renderContent = () => {
    return (
      <div>
        <Table
          className="customScrollBarStyle"
          rowKey={(row: any) => row.id}
          pagination={false}
          expandable={{
            columnWidth: 55,
            expandedRowRender: (record: any) => renderExpandData(record),
          }}
          scroll={{ y: windowSize?.height ? windowSize.height - 360 : 600 }}
          loading={isLoading || isFetchingNextPage}
          columns={columns}
          dataSource={stocks ?? []}
          size="small"
          locale={{
            emptyText: <Empty description="No Stocks" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
          }}
        />
      </div>
    );
  };

  return (
    <>
      <Card
        styles={{
          body: {
            padding: 0,
            height: "calc(100vh - 320px)",
          },
        }}
        className="stockList"
        id="stockList"
        title={
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <span>Stocks</span>
            <Space direction="horizontal">
              <Space>
                <Input.Search
                  disabled={isLoading}
                  placeholder="Search stock"
                  onSearch={(val) => {
                    setSearchString(val);
                  }}
                  onChange={(val) => setSearchStringValue(val.currentTarget.value)}
                  value={searchStringValue}
                  style={{ width: 400 }}
                  allowClear
                />
                <Button icon={<ReloadOutlined />} disabled={isLoading} onClick={handleRefresh}>
                  Refresh
                </Button>
              </Space>
            </Space>

            <span style={{ display: "flex", alignItems: "center" }}>
              <span style={{ paddingRight: 16 }}>Total: {totalItemCount ?? "-"}</span>
              {selectedCompany?.userRole === UserRole.admin.label || selectedCompany?._companyId === "all" ? (
                <>
                  <Button icon={<PlusOutlined />} type="primary" onClick={handleAddStock}>
                    Add Stock
                  </Button>
                </>
              ) : (
                <></>
              )}
            </span>
          </div>
        }
      >
        <PrintPage content={renderContent()} />
      </Card>
      <AddSelectCompanyModal entityName={EnitityName.Stock} ref={addModalRef} onSubmit={handleNavigateToAdd} />
    </>
  );
};

export default StockTable;
