import { getIntegrationConfigs } from "@/apis/integration.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

type useIntegrationConfigListQueryProps = {
  searchString?: string;
};

const useIntegrationConfigListQuery = ({ searchString }: useIntegrationConfigListQueryProps) => {
  const { profile } = useSelector((state: any) => state.user);

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const queryClient = useQueryClient();

  const {
    data: integrationConfigList,
    isFetching,
    isFetched,
    isLoading,
  } = useQuery({
    queryKey: [
      CacheKeys.integrations,
      CacheKeys.config,
      CacheKeys.list,
      {
        profileId: profile?.id,
        companyId: selectedCompanyId,
        searchString: searchString,
      },
    ],
    queryFn: async () => {
      const response = await getIntegrationConfigs({
        searchString: searchString,
        companyId: selectedCompanyId,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch integration configs");
    },
    ...defaultQueryConfig,
    enabled: !!profile?.id,
    gcTime: Infinity,
    staleTime: Infinity,
  });

  const invalidateIntegrationConfigs = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.integrations, CacheKeys.config, CacheKeys.list],
    });
  }, [queryClient]);

  const totalItemCount = useMemo(() => integrationConfigList?.length ?? 0, [integrationConfigList?.length]);

  return {
    integrationConfigList,
    isLoading,
    invalidateIntegrationConfigs,
    isFetching,
    isFetched,
    totalItemCount,
  };
};

export default useIntegrationConfigListQuery;
