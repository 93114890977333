import WaterRightTag from "@/components/WaterRightTag/WaterRightTag";
import { uniq } from "lodash";
import { FC, useMemo } from "react";

interface WaterRightTagListProps {
  internalTags?: any[];
  externalTags?: any[];
  style?: any;
  tagStyle?: any;
}

const WaterRightTagList: FC<WaterRightTagListProps> = (props) => {
  const { internalTags, externalTags, style, tagStyle } = props;

  const tags = useMemo(() => uniq((internalTags?.map((tag) => tag.label) ?? []).concat(externalTags ?? [])), [internalTags, externalTags]);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        overflow: "hidden",
        alignItems: "flex-start",
        ...style,
      }}
    >
      {tags.map((tag, index: number) => (
        <WaterRightTag
          style={{
            ...tagStyle,
            whiteSpace: "normal",
            wordBreak: "break-word",
            textAlign: "center",
            display: "inline-block",
          }}
          status={tag}
          key={index}
        />
      ))}
    </div>
  );
};

export default WaterRightTagList;
