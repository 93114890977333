/**
 * RSA Encryption Utility
 *
 * This file provides encryption/decryption functionality similar to the C# RSA implementation
 * using Web Crypto API for browser environments.
 */

/**
 * Imports a public key from PEM format
 * @param {string} publicKeyPem - The public key in PEM format
 * @returns {Promise<CryptoKey>} - The imported public key
 */
export const importPublicKeyFromPem = async (publicKeyPem: string) => {
  try {
    // Remove headers and newlines
    const pemContent = publicKeyPem.replace("-----BEGIN PUBLIC KEY-----", "").replace("-----END PUBLIC KEY-----", "").replace(/\s+/g, "");

    // Convert base64 to ArrayBuffer
    const binaryDer = base64ToArrayBuffer(pemContent);

    // Import the key
    return await window.crypto.subtle.importKey(
      "spki",
      binaryDer,
      {
        name: "RSA-OAEP",
        hash: { name: "SHA-256" },
      },
      true,
      ["encrypt"]
    );
  } catch (error) {
    console.error("Error importing public key:", error);
    throw error;
  }
};

/**
 * Encrypts data using RSA with a public key
 * @param {string} publicKeyPem - The public key in PEM format
 * @param {string} data - The data to encrypt
 * @returns {Promise<string>} - The Base64 encoded encrypted data
 */
export const encryptData = async (publicKeyPem: string, data: string) => {
  try {
    // Import the public key
    const publicKey = await importPublicKeyFromPem(publicKeyPem);

    // Convert the data to bytes
    const dataBytes = new TextEncoder().encode(data);

    // Encrypt the data
    const encryptedData = await window.crypto.subtle.encrypt({ name: "RSA-OAEP" }, publicKey, dataBytes);

    // Return as Base64
    return arrayBufferToBase64(encryptedData);
  } catch (error) {
    console.error("Error encrypting data:", error);
    throw error;
  }
};

/**
 * Helper function to convert ArrayBuffer to Base64 string
 * @param {ArrayBuffer} buffer - The buffer to convert
 * @returns {string} - Base64 string
 */
const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  const bytes = new Uint8Array(buffer);
  let binary = "";
  for (let i = 0; i < bytes.length; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
};

/**
 * Helper function to convert Base64 string to ArrayBuffer
 * @param {string} base64 - The Base64 string to convert
 * @returns {ArrayBuffer} - The resulting array buffer
 */
const base64ToArrayBuffer = (base64: string) => {
  const binaryString = atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};
