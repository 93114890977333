import { getIntegrationTemplate } from "@/apis/integration.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

type useIntegrationTemplateQueryProps = {
  templateId: string;
};

const useIntegrationTemplateQuery = ({ templateId }: useIntegrationTemplateQueryProps) => {
  const queryClient = useQueryClient();

  const {
    data: integrationTemplate,
    isFetching,
    isFetched,
    isLoading,
  } = useQuery({
    queryKey: [
      CacheKeys.integrations,
      CacheKeys.template,
      {
        id: templateId,
      },
    ],
    queryFn: async () => {
      console.log("GETTING TEMPLATE=> ", templateId);
      const response = await getIntegrationTemplate({
        templateId,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch integration template");
    },
    ...defaultQueryConfig,
    enabled: !!templateId,
    gcTime: Infinity,
    staleTime: Infinity,
  });

  const invalidateIntegrationTemplates = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.integrations, CacheKeys.template, CacheKeys.list],
    });
  }, [queryClient]);

  return {
    integrationTemplate,
    isLoading,
    invalidateIntegrationTemplates,
    isFetching,
    isFetched,
  };
};

export default useIntegrationTemplateQuery;
