import { get, post, put } from "@/services/request";

export const getIntegrationConfigs = (request: { searchString?: string; companyId?: string }) => {
  return get(`integration/configs?searchString=${request.searchString}&companyId=${request.companyId}`);
};

export const getIntegrationTemplates = (request: { searchString?: string }) => {
  return get(`integration/templates?searchString=${request.searchString}`);
};

export const getIntegrationTemplate = (request: { templateId: string }) => {
  return get(`integration/templates/${request.templateId}`);
};

export const getIntegrationConfig = (id: any) => {
  return get(`integration/configs/${id}`);
};

export const addIntegrationConfig = (request: { companyId?: string; templateId: string; configuration: { [key: string]: string } }) => {
  return post(`integration/configs`, request);
};

export const updateIntegrationConfig = (id: string, request: { configuration: { [key: string]: string } }) => {
  return put(`integration/configs/${id}`, request);
};

export const addIntegrationTemplate = (request: any) => {
  return post(`integration/templates`, request);
};

export const updateIntegrationTemplate = (request: any) => {
  return put(`integration/templates/${request.id}`, request);
};

export const generateConfigEncryptionKey = () => {
  return get(`integration/configs/encryptionkey`);
};

export const rollIntegrationConfigEncryptionKey = (id: string) => {
  return get(`integration/configs/${id}/encryptionkey/roll`);
};
