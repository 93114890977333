import { getIntegrationConfig } from "@/apis/integration.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

type useIntegrationConfigQueryProps = {
  id?: string;
};

const useIntegrationConfigQuery = ({ id }: useIntegrationConfigQueryProps) => {
  const queryClient = useQueryClient();

  const {
    data: integrationConfig,
    isFetching,
    isFetched,
    isLoading,
  } = useQuery({
    queryKey: [CacheKeys.integrations, CacheKeys.config, { id }],
    queryFn: async () => {
      const response = await getIntegrationConfig(id);

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch integration config");
    },
    ...defaultQueryConfig,
    enabled: !!id,
    gcTime: Infinity,
    staleTime: Infinity,
  });

  const invalidateIntegrationConfig = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.integrations, CacheKeys.config],
    });
  }, [queryClient]);

  return {
    integrationConfig,
    isLoading,
    invalidateIntegrationConfig,
    isFetching,
    isFetched,
  };
};

export default useIntegrationConfigQuery;
