import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { useQuery } from "@tanstack/react-query";
import posthog from "posthog-js";
import { useCallback } from "react";
import { useAuth } from "react-oidc-context";

export enum FeatureFlagType {
  ANALYTICS_DASHBOARD = "ANALYTICS_DASHBOARD",
  IMPERSONATION = "IMPERSONATION",
  PRINT_PAGE = "PRINT_PAGE",
  DWR_INTEGRATION = "DWR_INTEGRATION",
  APPEARENCE = "APPEARENCE",
  DASHBOARD_ORDERS = "DASHBOARD_ORDERS",
  INTEGRATIONS = "INTEGRATIONS",
}

function useFeatureFlag(feature: FeatureFlagType) {
  const auth = useAuth();

  const hasFeatureFlag = useCallback(async () => {
    if (!auth?.user?.profile?.sub || !posthog) {
      return false;
    }

    //This is needed to make sure that the user is identified by posthog before checking the feature flag
    await new Promise((resolve) => setTimeout(resolve, 1000));

    const hasFeature = posthog.isFeatureEnabled(feature);
    return hasFeature;
  }, [auth?.user?.profile?.sub, feature]);

  const { data: hasFeature } = useQuery({
    queryKey: [
      CacheKeys.featureFlag,
      feature,
      {
        userId: auth?.user?.profile?.sub,
      },
    ],
    queryFn: hasFeatureFlag,
    ...defaultQueryConfig,
    enabled: !!feature && !!auth?.user?.profile?.sub,
    staleTime: Infinity,
    gcTime: Infinity,
  });

  return {
    hasFeature,
  };
}

export default useFeatureFlag;
