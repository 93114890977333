import { manualRenewRestriction, syncRestrictionCalculations } from "@/apis/restriction.api";
import LookupLabel from "@/components/LookupLabel/LookupLabel";
import CompanyLabel from "@/components/Lookups/CompanyLabel";
import PrintPage from "@/components/PrintPage/PrintPage";
import RestrictionPopover from "@/components/RestrictionPopover/RestrictionPopover";
import StatusTag from "@/components/StatusTag/StatusTag";
import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import { routes } from "@/configs";
import { UserRole } from "@/dtos/user.dto";
import { RestrictionView } from "@/pages";
import useListRestrictionQuery from "@/queries/useListRestrictionQuery";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb } from "@/stores/breadcrumbs.store";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { useIntersectionObserver, useWindowSize } from "@uidotdev/usehooks";
import { Button, Card, DatePicker, Empty, Input, Popconfirm, Popover, Space, Switch, Table, message } from "antd";
import dayjs from "dayjs";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AddSelectCompanyModal, { AddSelectCompanyModalRef, EnitityName } from "../AddSelectCompanyModal/AddSelectCompanyModal";

interface Props {
  showAddRestriction: boolean;
  showHeader: boolean;
  style?: React.CSSProperties;
  waterRightId?: any;
}

const RestrictionTable: FC<Props> = (props) => {
  const { style, showAddRestriction, showHeader, waterRightId } = props;

  const dispatch = useAppDispatch();

  const { selectedCompanyId, selectedCompany } = useSelector((state: any) => state.company);

  const { navigate, updateQueryParams, queryParams } = useCustomNavigate();

  // const [restrictions, setRestrictions] = useState<any[]>([]);
  // const [pagedRestrictions, setPagedRestrictions] = useState<any>({
  //   list: [],
  //   pagination: {},
  // });
  const [isActive, setIsActive] = useState<boolean | null>(true);
  const [searchStringValue, setSearchStringValue] = useState<string | undefined>("");
  const [searchString, setSearchString] = useState<string | undefined>("");
  const [calculations, setCalculations] = useState<any[]>([]);
  const [date, setDate] = useState<any | null>(null);

  const [columns, setColumns] = useState<any[]>([]);

  // const [loading, setLoading] = useState<boolean>(true);
  const [loadingRenewRestriction, setLoadingRenewRestriction] = useState<boolean>(false);
  const [loadingCalculations, setLoadingCalculations] = useState<boolean>(true);

  const currentYear = dayjs().year();

  const [restrictionsDownRef, restrictionsDownEntry] = useIntersectionObserver();

  const windowSize = useWindowSize();

  const {
    restrictionList: restrictions,
    isLoading,
    invalidateRestrictions,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    totalItemCount,
  } = useListRestrictionQuery({
    isActive,
    searchString,
    waterRightId,
    year: date ? dayjs(date).year() : null,
  });

  const refreshRestrictionListCalculations = useCallback(() => {
    handleRefresh();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (restrictionsDownEntry?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line
  }, [restrictionsDownEntry?.time, restrictionsDownEntry?.isIntersecting, hasNextPage]);

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [restrictions, calculations]);

  useEffect(() => {
    refreshCalculations();
    // eslint-disable-next-line
  }, [isActive, selectedCompany, date, selectedCompanyId]);

  const handleRefresh = () => {
    setSearchString("");
    setSearchStringValue("");
    refreshCalculations();
    invalidateRestrictions();
  };

  const refreshCalculations = async () => {
    setLoadingCalculations(true);
    const response = await syncRestrictionCalculations({});
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setCalculations(data.value);
      }
    }
    setLoadingCalculations(false);
  };

  const determineValue = (id: string, type: string, postfix: any = undefined) => {
    const existingCalculation = calculations.find((calculation) => calculation.id === id);
    if (existingCalculation) {
      // if (type === 'inchesPerAcre' && existingCalculation[type] === null) return "-"
      return existingCalculation[type];
    } else return undefined;
  };

  const calculateColumns = () => {
    let tempColumns = [
      {
        title: "Restriction Name",
        key: "name",
        dataIndex: "name",
        fixed: "left",
        render: (val: any, record: any, index: any) => (
          <>
            <Button style={{ paddingLeft: 0, whiteSpace: "normal", wordBreak: "break-word", textAlign: "left" }} type="link" onClick={() => handleViewRestriction(record)}>
              {val}
            </Button>
            {index === restrictions?.length - 1 ? <span ref={restrictionsDownRef} /> : null}
          </>
        ),
      },
      {
        title: "Type",
        key: "type",
        dataIndex: "type",
        render: (val: string, record: any) => {
          if (!record?.myfa) return <LookupLabel lookupType="restrictiontype" value={val} />;
          else
            return (
              <>
                <LookupLabel lookupType="restrictiontype" value={val} /> - MYFA
              </>
            );
        },
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (val: any, record: any) => <StatusTag status={val} />,
      },
      // {
      //     title: "Start Date",
      //     key: "startDate",
      //     dataIndex: "startDate",
      //     render: (val: any, record: any) => dayjs(record.startDate).format(constants.dateFormat),
      // },
      // {
      //     title: "End Date",
      //     key: "endDate",
      //     dataIndex: "endDate",
      //     render: (val: any, record: any) => dayjs(record.endDate).format(constants.dateFormat),
      // },
      {
        title: (
          <>
            Term Quantity
            <br />
            <small>(Acre Feet)</small>
          </>
        ),
        key: "termQuantity",
        dataIndex: "termQuantity",
        width: 160,
        render: (val: any) => <UnitsConverter fromUnits="acrefeet" toUnits="acrefeet" value={val} />,
      },
      {
        title: (
          <>
            Remaining Quantity
            <br />
            <small>({selectedCompanyId ? <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric} /> : "Acre Feet"})</small>
          </>
        ),
        key: "remainingQuantity",
        render: (val: any, record: any) => (
          <UnitsConverter fromUnits="gallons" toUnits={selectedCompanyId ? selectedCompany?.settings?.metric : "acrefeet"} value={determineValue(record.id, "remainingUsage")} />
        ),
      },
      {
        title: (
          <>
            Current Year Usage
            <br />
            <small>({selectedCompanyId ? <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric} /> : "Acre Feet"})</small>
          </>
        ),
        key: "currentYearUsage",
        render: (val: any, record: any) => {
          const remainingYears = determineValue(record.id, "remainingYears");

          return remainingYears === 0 && record?.status === false ? (
            <>N/A</>
          ) : (
            <UnitsConverter fromUnits="gallons" toUnits={selectedCompanyId ? selectedCompany?.settings?.metric : "acrefeet"} value={determineValue(record.id, "currentYearUsage")} />
          );
        },
      },
      {
        title: (
          <>
            Current Year Remaining
            <br />
            <small>({selectedCompanyId ? <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric} /> : "Acre Feet"})</small>
          </>
        ),
        key: "currentYearRemaining",
        render: (val: any, record: any) => {
          const remainingYears = determineValue(record.id, "remainingYears");
          let baseLineQuantity = determineValue(record.id, "adjustedBaselineQuantity");
          if (baseLineQuantity === undefined) baseLineQuantity = determineValue(record.id, "baselineQuantity");
          const currentYearUsage = determineValue(record.id, "currentYearUsage");
          const remaining = determineValue(record.id, "remainingUsage");
          const currentYearRemaining = remaining > baseLineQuantity - currentYearUsage ? baseLineQuantity - currentYearUsage : remaining;

          return remainingYears === 0 && record?.status === false ? (
            0
          ) : (
            <UnitsConverter fromUnits="gallons" toUnits={selectedCompanyId ? selectedCompany?.settings?.metric : "acrefeet"} value={currentYearRemaining} />
          );
        },
      },
      // {
      //     title: "Remaining Years",
      //     key: "remainingYears",
      //     dataIndex: "remainingYears",
      //     align: 'center',
      //     width: 160,
      //     render: (val: any, record: any) => determineValue(record.id, 'remainingYears') ?? '-'
      // },
      {
        title: "Remaining Yearly Average",
        key: "yearlyAverage",
        dataIndex: "yearlyAverage",
        render: (val: any, record: any) => {
          const remainingYears = determineValue(record.id, "remainingYears");

          return remainingYears === 0 && record?.status === false ? (
            <>N/A</>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <RestrictionPopover
                status={record?.status}
                companyId={record?.companyId}
                selectedCompany={selectedCompany}
                startDate={dayjs(record?.startDate)}
                endDate={dayjs(record?.endDate)}
                remainingYears={determineValue(record.id, "remainingYears")}
                calculation={calculations.find((x) => x.id === record?.id)}
                restriction={record}
                handleRefresh={handleRefresh}
                restrictionTable
              />
              <div style={{ fontSize: "0.8em" }}>
                <div>
                  <UnitsConverter
                    fromUnits="gallons"
                    toUnits={selectedCompanyId ? selectedCompany?.settings?.metric : "acrefeet"}
                    showUnitsLabel
                    value={determineValue(record.id, "remainingYearlyAverage")}
                  />
                </div>
                {determineValue(record.id, "inchesPerAcre") !== null && (
                  <div>
                    <UnitsConverter fromUnits="inchesperacre" toUnits="inchesperacre" value={determineValue(record.id, "inchesPerAcre")} postFix="Inches / Acre" />
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
      {
        title: "Actions",
        key: "action",
        width: 170,
        align: "center",
        render: (value: any, record: any) => (
          <>
            <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleViewRestriction(record)}>
              View
            </Button>
            {selectedCompany?.userRole === UserRole.admin.label && " | "}
            {selectedCompany?.userRole === UserRole.admin.label && dayjs(record?.endDate).year() < currentYear && record?.renewedRestrictionId === null ? (
              <Popconfirm title={"Renew Restriction"} description="Are you sure you wish to renew this restriction?" okText="Yes" cancelText="Cancel" onConfirm={() => handleRenewRestriction(record)}>
                <Button loading={loadingRenewRestriction} type="link">
                  Renew
                </Button>
              </Popconfirm>
            ) : (
              <Popover
                content={
                  <>
                    {dayjs(record?.endDate).year() >= currentYear && <div>The restriction is not due for renewal</div>}
                    {record?.renewedRestrictionId !== null && <div>The restriction has already been renewed</div>}
                  </>
                }
              >
                <Button disabled type="link">
                  Renew
                </Button>
              </Popover>
            )}
          </>
        ),
      },
    ];

    if (!selectedCompanyId) {
      tempColumns.splice(8, 0, {
        title: "Company",
        key: "companyId",
        dataIndex: "companyId",
        render: (val: any, record: any) => <CompanyLabel companyId={val} />,
      });
    }

    setColumns(tempColumns);
  };

  const handleRenewRestriction = async (record: any) => {
    setLoadingRenewRestriction(true);

    const response = await manualRenewRestriction(record.id);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        message.success(`Restriction ${record.name} has been manually renewed`);
        handleRefresh();
      } else {
        message.error(`Failed to manually renew restriciton ${record.name}`);
      }
    }

    setLoadingRenewRestriction(false);
  };

  const handleAddRestriction = () => {
    if (selectedCompany?._companyId === "all") {
      addModalRef.current?.open();
      return;
    } else {
      handleNavigateToAdd();
    }
  };

  const handleNavigateToAdd = () => {
    dispatch(
      addBreadcrumb({
        type: `Restriction / Add`,
        url: `restriction/add`,
      })
    );
    navigate(routes.restrictionAdd);
  };

  const handleViewRestriction = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `${record.name}`,
        url: `restriction/${record.id}/view`,
      })
    );
    navigate(routes.restrictionView, { id: record.id });
  };

  // const onSearchChange = (searchString: any) => {
  //   setSearchString(searchString);
  // };

  // const onSearch = (searchString: any) => {
  //   setSearchString(searchString);
  //   if (selectedCompanyId) refreshRestrictionsList(searchString);
  //   else
  //     refreshPagedRestrictionsList(searchString, {
  //       page: queryParams?.page ?? 1,
  //       pageSize: queryParams?.pageSize ?? 10,
  //     });
  //   refreshCalculations();
  // };

  // const handlePagedRefresh = () => {
  //   setSearchString(undefined);
  //   updateQueryParams({ page: 1, pageSize: 10 });
  //   refreshPagedRestrictionsList();
  // };

  const renderExpandData = (record: any) => {
    return <RestrictionView refreshRestrictionListCalculations={refreshRestrictionListCalculations} overrideId={record.id} showHeader={false} />;
  };

  const handleOnChange = (value: boolean) => {
    if (!value) setIsActive(null);
    else setIsActive(true);
  };

  const addModalRef = useRef<AddSelectCompanyModalRef>(null);

  const renderContent = () => {
    return (
      <div>
        <Table
          className="customScrollBarStyle"
          pagination={false}
          rowKey={(row: any) => row.id}
          scroll={{ y: windowSize?.height ? (waterRightId !== undefined && waterRightId ? windowSize.height - 730 : windowSize.height - 385) : 740 }}
          // scroll={{ y: windowSize?.height ? windowSize.height - 385 : 600 }}
          // style={{
          //   height: 400,
          // }}
          expandable={{
            columnWidth: 55,
            expandedRowRender: (record: any, index: number, indent: number, expanded: boolean) => (expanded ? renderExpandData(record) : null),
          }}
          loading={isLoading || isFetchingNextPage}
          columns={columns}
          dataSource={restrictions ?? []}
          size="small"
          locale={{
            emptyText: <Empty description="No Restrictions" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
          }}
        />
      </div>
    );
  };

  return (
    <>
      <Card
        styles={{
          body: {
            height: waterRightId !== undefined && waterRightId ? "calc(100vh - 650px)" : "calc(100vh - 320px)",
            padding: 0,
          },
        }}
        className="restrictionList"
        id="restrictionList"
        title={
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <span>Restrictions</span>
            <Space direction="horizontal">
              {showHeader && (
                <Space>
                  <Input.Search
                    disabled={isLoading}
                    placeholder="Search restrictions"
                    onSearch={(val) => {
                      setSearchString(val);
                      refreshCalculations();
                    }}
                    onChange={(val) => setSearchStringValue(val.currentTarget.value)}
                    value={searchStringValue}
                    style={{ width: 400 }}
                    allowClear
                  />
                  <Button icon={<ReloadOutlined />} disabled={isLoading} onClick={handleRefresh}>
                    Refresh
                  </Button>
                  <Switch defaultChecked onChange={handleOnChange} checkedChildren="Active Only" unCheckedChildren="All" />
                  <DatePicker
                    value={date}
                    onChange={(date) => {
                      if (date === null) setDate(null);
                      else setDate(dayjs(date));
                    }}
                    picker="year"
                    placeholder="Filter on Year"
                    allowClear={true}
                    renderExtraFooter={(date) => (
                      <>
                        <Button
                          danger
                          onClick={() => {
                            setDate(null);
                          }}
                        >
                          Clear Year
                        </Button>
                      </>
                    )}
                  />
                </Space>
              )}
            </Space>

            <span style={{ display: "flex", alignItems: "center" }}>
              <span style={{ paddingRight: 16 }}>Total: {totalItemCount ?? "-"}</span>
              {showAddRestriction && (selectedCompany?.userRole === UserRole.admin.label || selectedCompany?._companyId === "all") ? (
                <>
                  <Button icon={<PlusOutlined />} type="primary" onClick={handleAddRestriction}>
                    Add Restriction
                  </Button>
                </>
              ) : (
                <></>
              )}
            </span>
          </div>
        }
      >
        {showAddRestriction ? <PrintPage content={renderContent()} /> : renderContent()}
      </Card>
      <AddSelectCompanyModal entityName={EnitityName.Restriction} ref={addModalRef} onSubmit={handleNavigateToAdd} />
    </>
  );
};

export default RestrictionTable;
