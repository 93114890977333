import CompanyLabel from "@/components/Lookups/CompanyLabel";
import PrintPage from "@/components/PrintPage/PrintPage";
import { constants, routes } from "@/configs";
import { UserRole } from "@/dtos/user.dto";
import useIntegrationConfigListQuery from "@/queries/useIntegrationConfigListQuery";
import useCustomNavigate from "@/services/useCustomNavigate";
import { RootState, useAppDispatch } from "@/stores";
import { addBreadcrumb } from "@/stores/breadcrumbs.store";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { useWindowSize } from "@uidotdev/usehooks";
import { Button, Card, Empty, Input, Space, Table } from "antd";
import { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AddSelectCompanyModal, { AddSelectCompanyModalRef, EnitityName } from "../AddSelectCompanyModal/AddSelectCompanyModal";
import dayjs from "dayjs";
import { IntegrationView } from "@/pages";
import "./IntegrationsTable.scss";

interface Props {
  style?: React.CSSProperties;
  showAddIntegration?: boolean;
}

const IntegrationsTable: FC<Props> = (props) => {
  const { style, showAddIntegration = true } = props;

  const dispatch = useAppDispatch();

  const { selectedCompanyId, selectedCompany } = useSelector((state: RootState) => state.company);

  const { navigate } = useCustomNavigate();

  const [columns, setColumns] = useState<any[]>([]);
  const [searchStringValue, setSearchStringValue] = useState<string | undefined>("");
  const [searchString, setSearchString] = useState<string | undefined>("");

  const windowSize = useWindowSize();

  const {
    integrationConfigList: integrations,
    isLoading,
    invalidateIntegrationConfigs,
    totalItemCount,
  } = useIntegrationConfigListQuery({
    searchString,
  });

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [integrations]);

  const calculateColumns = () => {
    const tempColumns: any[] = [
      {
        title: "Integration Name",
        key: "templateName",
        dataIndex: "templateName",
        render: (val: any, record: any, index: any) => (
          <>
            <Button style={{ paddingLeft: 0, whiteSpace: "normal", wordBreak: "break-word", textAlign: "left" }} type="link" onClick={() => handleViewIntegration(record)}>
              {val}
            </Button>
          </>
        ),
      },
      {
        title: "Last Processed",
        key: "lastSynchronized",
        dataIndex: "lastSynchronized",
        width: 180,
        render: (val: any) => <>{val ? dayjs(val).format(constants.dateTimeFormat) : "Never"}</>,
      },
      {
        title: "Status",
        key: "syncStatus",
        dataIndex: "syncStatus",
        width: 150,
        render: (val: any) => <>{val ?? "-"}</>,
      },
      {
        title: "Successfully Synced",
        key: "lastSuccessfullySynchronized",
        dataIndex: "lastSuccessfullySynchronized",
        width: 180,
        render: (val: any) => <>{val ? dayjs(val).format(constants.dateTimeFormat) : "Never"}</>,
      },
      {
        title: "Actions",
        key: "action",
        width: 150,
        render: (value: any, record: any) => (
          <>
            <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleViewIntegration(record)}>
              View
            </Button>
          </>
        ),
      },
    ];

    // if (!selectedCompanyId) {
    //   tempColumns.splice(1, 0, {
    //     title: "Company",
    //     key: "companyId",
    //     dataIndex: "companyId",
    //     render: (val: any, record: any) => (val ? <CompanyLabel companyId={val} /> : "*ALL*"),
    //   });
    // }

    setColumns(tempColumns);
  };

  const handleNavigateToAdd = () => {
    dispatch(
      addBreadcrumb({
        type: `Integration / Add`,
        url: `integration/add`,
      })
    );
    navigate(routes.integrationAdd);
  };

  const handleAddIntegration = () => {
    handleNavigateToAdd();
  };

  const handleViewIntegration = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `${record.name}`,
        url: `integration/${record.id}/view`,
      })
    );
    navigate(routes.integrationView, { id: record.id });
  };

  const handleRefresh = () => {
    setSearchString("");
    setSearchStringValue("");
    invalidateIntegrationConfigs();
  };

  const renderExpandData = (record: any) => {
    return <IntegrationView showHeader={false} overrideId={record.id} />;
  };

  const addModalRef = useRef<AddSelectCompanyModalRef>(null);

  const renderContent = () => {
    return (
      <div>
        <Table
          className="customScrollBarStyle"
          pagination={false}
          rowKey={(row: any) => row.id}
          loading={isLoading}
          columns={columns}
          dataSource={integrations ?? []}
          scroll={{ y: windowSize?.height ? windowSize.height - 360 : 600 }}
          // style={{
          //   height: 400,
          // }}
          size="small"
          expandable={{
            columnWidth: 55,
            expandedRowRender: (record: any) => renderExpandData(record),
          }}
          locale={{
            emptyText: <Empty description="No Integrations" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
          }}
        />
      </div>
    );
  };

  return (
    <>
      <Card
        styles={{
          body: {
            height: "calc(100vh - 320px)",
            padding: 0,
          },
        }}
        id="intrgrationsTable"
        title={
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <span>Integrations</span>
            <Space direction="horizontal">
              <Space>
                <Input.Search
                  disabled={isLoading}
                  placeholder="Search integrations"
                  onSearch={(val) => {
                    setSearchString(val);
                  }}
                  onChange={(val) => setSearchStringValue(val.currentTarget.value)}
                  value={searchStringValue}
                  style={{ width: 400 }}
                  allowClear
                />
                <Button icon={<ReloadOutlined />} disabled={isLoading} onClick={handleRefresh}>
                  Refresh
                </Button>
              </Space>
            </Space>

            <span style={{ display: "flex", alignItems: "center" }}>
              <span style={{ paddingRight: 16 }}>Total: {totalItemCount ?? "-"}</span>
              {showAddIntegration && (selectedCompany?.userRole === UserRole.admin.label || selectedCompany?._companyId === "all") ? (
                <>
                  <Button onClick={handleAddIntegration} type="primary" icon={<PlusOutlined />}>
                    Add Integration
                  </Button>
                </>
              ) : (
                <></>
              )}
            </span>
          </div>
        }
      >
        {showAddIntegration ? <PrintPage content={renderContent()} /> : renderContent()}
      </Card>
      <AddSelectCompanyModal entityName={EnitityName.Field} ref={addModalRef} onSubmit={handleNavigateToAdd} />
    </>
  );
};

export default IntegrationsTable;
