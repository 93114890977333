import { Tag } from "antd";
import { FC } from "react";

interface Props {
  tags?: any[];
}

const CompanyTagList: FC<Props> = (props) => {
  const { tags } = props;

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        overflow: "hidden",
        alignItems: "flex-start",
      }}
    >
      {tags?.map((tag) => (
        <Tag
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            textAlign: "center",
            display: "inline-block",
          }}
          id="companyTag"
        >
          {tag}
        </Tag>
      ))}
    </div>
  );
};

export default CompanyTagList;
